import React from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

// @mui
import {
    Button,
    TextField,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Stack,

    Alert,
    AlertTitle,
} from '@mui/material';

import server from 'server';
import i18n from 'i18';
import { LoadingButton } from '@mui/lab';


export default class LinkInviteCodeDialog extends React.Component {

    onClose = () => { };

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            inviteCode: "",
            alertText: null,
            scannerOpen: false,
            loading: false,
        };

        this.onClose = props.onClose;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen || false,
                inviteCode: "",
                alertText: null,
                scannerOpen: false,
                loading: false,
            };
        }

        return null;
    }

    async linkInviteCode() {
        this.setState({ loading: true });
        server.userInviteCodes.applyInviteCode(this.state.inviteCode).then((result) => {
            if (result.error !== "OK") {
                this.setState({ alertText: result.message, loading: false });
            } else {
                this.setState({ loading: false });
                this.onClose();
            }
        });
    }

    handleInviteValueChanged(value) {
        this.setState({ inviteCode: value });
    }

    render() {

        return (
            <Dialog open={this.state.dialogOpen}>
                <DialogTitle>{i18n.t(`dialogs.showInviteCode`)}</DialogTitle>
                <DialogContent>
                    {
                        this.state.alertText && (
                            <Alert key="alert" severity="error">
                                <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                                {this.state.alertText}
                            </Alert>
                        )
                    }
                    
                    <Stack direction="column">
                        <Button 
                            variant="contained" 
                            sx={{ my: 3 }}
                            onClick={() => this.setState({ scannerOpen: !this.state.scannerOpen })}
                        >
                            {i18n.t("buttons.toggleScanner")}
                        </Button>
                        
                        {
                            this.state.scannerOpen &&
                            (
                                <BarcodeScannerComponent
                                    width={200}
                                    height={200}
                                    onUpdate={(err, result) => {
                                        if (result) {
                                            this.setState({ scannerOpen: false });
                                            this.handleInviteValueChanged(result.text);
                                        }
                                    }}
                                />
                            )
                        }

                        <TextField
                            autoFocus
                            margin="dense" 
                            type="text" 
                            fullWidth 
                            value={this.state.inviteCode}
                            label={i18n.t("columns.inviteCode")}
                            onChange={(event) => this.handleInviteValueChanged(event.target.value)} 
                        />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.loading} onClick={() => this.onClose()}>{i18n.t("buttons.cancel")}</Button>
                    <LoadingButton loading={this.state.loading} onClick={() => this.linkInviteCode()}>{i18n.t("buttons.save")}</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

}
