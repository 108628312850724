import React from "react";
import Fields from "./fields";
import { Box, Button } from "@mui/material";
import i18 from "../../i18";

export default class FilterItemsAbstract extends React.Component {
    Head;
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            currentField: null,
            clearAllFilters: false,
        };
        this.Head = props.Head;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filters !== prevState.filters) {
            this.props.handleFilterItems(this.state.filters);
        }
    }

    handleFilter = (field, ids) => {
        if (ids.length === 0) {
            this.removeFilter(field);
            return;
        }
        if (field === this.state.currentField) {
            this.updateFilter(field, ids);
        } else {
            this.addFilter(field, ids);
            this.setState({ currentField: field });
        }
    };
    updateFilter = (field, ids) => {
        const index = this.state.filters.findIndex(
            (filter) => filter.field === field
        );
        if (index !== -1) {
            const updatedFilters = [...this.state.filters];
            updatedFilters[index] = { field, op: "@", value: ids };
            this.setState({ filters: updatedFilters, clearAllFilters: false });
        }
    };
    addFilter = (field, ids) => {
        const newFilter = { field, op: "@", value: ids };
        this.setState((prevState) => ({
            filters: [...prevState.filters, newFilter],
            clearAllFilters: false,
        }));
    };
    removeFilter = (field) => {
        const updatedFilters = this.state.filters.filter(
            (filter) => filter.field !== field
        );
        this.setState({
            filters: updatedFilters,
            currentField: null,
            clearAllFilters: false,
        });
    };

    render() {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                {this.Head.map((field) => {
                    if (!field.filterCell) return null;
                    const Field = Fields[field.filterCell];
                    return (
                        <React.Fragment key={field.filterBy}>
                            {field.isSingle && this.state.filters.length !== 0 && (
                                <Button
                                    color="primary"
                                    onClick={() => this.setState({ clearAllFilters: true })}
                                >
                                    {i18.t("buttons.showAll")}
                                </Button>
                            )}
                            <Field
                                field={field}
                                handleFilter={this.handleFilter}
                                clearAllFilters={this.state.clearAllFilters}
                            />
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    }
}
