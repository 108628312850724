import {
    AddCircleOutline as AddCircleOutlineIcon,
    AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
    CancelOutlined as CancelOutlinedIcon,
    LocalHospitalOutlined as LocalHospitalOutlinedIcon,
    PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
    ScienceOutlined as ScienceOutlinedIcon,
    SendOutlined as SendOutlinedIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import i18n from 'i18';
import { useState } from 'react';

const statusIcons = {
    created: AddCircleOutlineIcon,
    cancelled: CancelOutlinedIcon,
    sold_to_user: PersonAddAlt1OutlinedIcon,
    probe_collected: ScienceOutlinedIcon,
    sent_to_lab: SendOutlinedIcon,
    result_received: AssignmentTurnedInOutlinedIcon,
    lab_received: LocalHospitalOutlinedIcon,
};

function IconWithTooltip({ IconComponent, color, status }) {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <Tooltip
            title={i18n.t(`enums.${status}`)}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -25],
                            },
                        },
                    ],
                },
            }}
        >
            <IconButton onClick={() => setShowTooltip(!showTooltip)}>
                <IconComponent color={color} />
            </IconButton>
        </Tooltip>
    );
}

function IconStatus(status) {
    const color = status === 'result_received' ? 'success' : status === 'sent_to_lab' ? 'action' : 'error';
    const IconComponent = statusIcons[status];

    if (!IconComponent) return <></>;


    return <IconWithTooltip IconComponent={IconComponent} color={color} status={status} />;
}

export default IconStatus;
