// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class TherapistsPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "therapists";
        this.Server = server.therapists;
        this.searchFields = ['user.firstName', 'user.lastName', 'comment', 'workCompany', 'phone'];
    
        this.Head = [
            { id: 'userId', object: 'user', display: 'firstName+lastName', label: 'user', alignRight: false, cell: 'Select', from: 'users' },
            { id: 'email', object: 'user', display: 'email', label: 'email', alignRight: false, cell: 'Text', disableEditor: true },
            { id: 'comment', label: 'comment', alignRight: false, cell: 'Text' },
            { id: 'workAddress', label: 'workAddress', alignRight: false, cell: 'Text' },
            { id: 'workCompany', label: 'workCompany', alignRight: false, cell: 'Text' },
            { id: 'resellerStatus', label: 'resellerStatus', alignRight: false, cell: 'Items', items: ['none', 'registered', 'approved', 'rejected'], disableEditor: true },
            { id: 'phone', label: 'phone', alignRight: false, cell: 'Text' },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];

    }
  
}
