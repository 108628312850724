import { useState } from 'react';
import useResponsive from 'hooks/useResponsive';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { MenuItem, Stack, IconButton, Popover, SvgIcon, Box, Link, Typography, } from '@mui/material';
import i18n from 'i18';
import Flags from 'country-flag-icons/react/3x2';
import config from 'config';
import helper from 'utils/helper';

// ----------------------------------------------------------------------

const langs = config.langs.map(it => {
    const FlagTag = Flags[it.iconKey];

    return { ...it, icon: (<FlagTag title={it.label} />) };
});

// ----------------------------------------------------------------------

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function LanguagePopover({ forceSmall }) {
    let [open, setOpen] = useState(null);

    const handleOpen = ev => {
        setOpen(ev.currentTarget);
    };

    const handleClose = lang => {
        setOpen(null);
        
        if (langs.find(l => l.value === lang)) {
            helper.changeLanguage(lang);
        }
    };

    const isDesktop = useResponsive('up', 'lg') && !forceSmall;
    const selectedLanguage = langs.find(l => l.value === i18n.resolvedLanguage) || langs.find(l => l.value === config.language);

    return (
        <>
            {isDesktop ? (
                <Box sx={{ mb: 2, mx: 2.5, cursor: "pointer" }}>
                    <Link underline="none" onClick={handleOpen}>
                        <StyledAccount>
                            <Box
                                sx={{ width: 24, height: 24 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                {selectedLanguage.icon}
                            </Box>
                            <Typography variant="subtitle2" sx={{ py: 1, ml: 2, color: 'text.primary' }}>
                                {selectedLanguage.label}
                            </Typography>
                        </StyledAccount>
                    </Link>
                </Box>
            ) : (
                <IconButton
                    onClick={handleOpen}
                    sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...(open && {
                            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                        }),
                    }}
                >
                    <Box
                        sx={{ width: 24, height: 24 }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        {selectedLanguage.icon}
                    </Box>
                </IconButton>
            )}


            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        "p": 1,
                        "mt": 1.5,
                        "ml": 0.75,
                        "width": 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Stack spacing={0.75}>
                    {langs.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === selectedLanguage.value}
                            onClick={() => handleClose(option.value)}
                        >
                            <SvgIcon sx={{ width: 28, mr: 2 }}>{option.icon}</SvgIcon>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </Popover>
        </>
    );
}
