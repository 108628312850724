import RequestAbstract from "../abstract";

export default class ResellerTests extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/reseller-tests";
    }

}
