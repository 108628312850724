import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
    Accordion,
    AccordionDetails,
    Box,
    Container,
    Link,
    Stack,
    Typography
} from '@mui/material';


import TestResultSingleRow from 'components/test-results/TestResultSingleRow';
import AllTestResultsSelect from '../../components/test-results/AllTestResultsSelect';
// view components
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//
import server from 'server';

import i18n from 'i18';
import { AppBox } from '../../sections/dashboard';
import { ExpandMore } from '@mui/icons-material';
import RichText from '../../components/richtext';
import { styled } from '@mui/material/styles';

const AccordionSummaryParent = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    "backgroundColor": 'rgba(0, 0, 0, .05)',
    "flexDirection": 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)'
    }
}));


const AccordionSummaryChild = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.7rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    "backgroundColor": '#F4F6F8',
    "flexDirection": 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)'
    },
    '&.MuiAccordionSummary-root': {
        transition: 'border-radius ease 0.3s'
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
        borderRadius: '15px 15px 0 0'
    },

}));

const AccordionChild = styled((props) => (
    <MuiAccordion {...props} />
))(({ theme }) => ({
    '&.MuiAccordion-root': {
        overflow: 'hidden'
    },
    '&.MuiAccordion-root:first-of-type': {
        borderRadius: '15px 15px 0 0'
    },
    '&.MuiAccordion-root:last-child': {
        borderRadius: '0 0 15px 15px'
    },
    '&.MuiAccordion-root:first-of-type:last-child': {
        borderRadius: '15px'
    },
}));

const AccordionDetailsChild = styled((props) => (
    <MuiAccordionDetails
        expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    "backgroundColor": '#fafafa',
    '&.MuiAccordionDetails-root': {
        transition: 'border-radius ease 0.3s',
        borderRadius: '0 0 15px 15px'
    },
}));

export default class UserTestResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testResultData: null,
            expanded: false
        };
    }

    async componentDidMount() {
        await this.fetchTest();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params.id !== this.props.params.id) {
            this.fetchTest();
        }
    }

    async fetchTest() {
        server.tests.getTestResult(this.props.params.id).then((data) => {
            this.setState({ testResultData: data });
        });
    }
    categorizeTestResults(testResults) {
        const categoryItems = testResults.filter(
            (result) => result.referenceInfo.referenceInfoCategory
        );
        // All categories
        let allCategories = [];
        categoryItems.forEach((item) => {
            const refInfoCategory = item.referenceInfo.referenceInfoCategory;
            allCategories.push(refInfoCategory);
            if (refInfoCategory.parentCategory)
                allCategories.push(refInfoCategory.parentCategory);
        });
        allCategories = allCategories.filter(
            (category, i, self) =>
                i === self.findIndex((t) => t.id === category.id)
        );

        // Parent categories (High Level)
        const parentCategories = categoryItems
            // map to referenceInfoCategory
            .map((item) => item.referenceInfo.referenceInfoCategory)
            // return parentCategory if existed, else return referenceInfoCategory
            .map(
                (refInfoCategory) =>
                    refInfoCategory.parentCategory || refInfoCategory
            )
            // Make unique values array
            .filter(
                (item, i, self) => i === self.findIndex((t) => t.id === item.id)
            )
            // add children field
            .map((parentCategory) => ({ ...parentCategory, children: [] }));

        const subCategories = allCategories.filter((cat) => cat.parentCategory);

        const categorizedItems = parentCategories.map((pc) => {
            return {
                ...pc,
                results: categoryItems.filter(
                    (item) =>
                        item.referenceInfo.referenceInfoCategory.id ===
                        pc.id
                ).sort((a, b) => a.referenceInfo.position - b.referenceInfo.position),
                children: subCategories
                    .filter((ac) => ac.parentCategory.id === pc.id)
                    .map((category) => ({
                        ...category,
                        results: categoryItems.filter(
                            (item) =>
                                item.referenceInfo.referenceInfoCategory.id ===
                                category.id
                        ).sort((a, b) => a.referenceInfo.position - b.referenceInfo.position)
                    }))
            };
        });
        return categorizedItems;
    }

    sortCategories(categories) {
        return categories.sort((a, b) => a.position - b.position);
    }

    isRichText(content) {
        const parsedContent = content ? JSON.parse(content) : [];

        if (parsedContent.length === 1 && parsedContent[0].type === "paragraph") {
            const children = parsedContent[0].children;

            if (children.length === 1 && children[0].text === "") {
                return false;
            }
        }
        return true;
    }

    renderResultCategoryItems(categories) {
        if (!categories || !categories.length) return null;
        return this.sortCategories(categories).map((category) => (
            <div>
                <Accordion
                    key={category.id}
                    defaultExpanded={!category.defaultFolded}
                >
                    <AccordionSummaryParent
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">{category.name}</Typography>
                    </AccordionSummaryParent>
                    <AccordionDetails>
                        {this.isRichText(category.description) ? (
                            <RichText
                                disableEditor={true}
                                value={category.description}
                            />
                        ) : null}
                        <Box sx={{ borderRadius: '20px' }}>
                            {category.results.map((result) => (
                                <TestResultSingleRow
                                    key={result.id}
                                    result={result}
                                />
                            ))}
                            {this.sortCategories(category.children).map((childCategory) => {
                                return (
                                    <AccordionChild key={childCategory.id}>
                                        <AccordionSummaryChild
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant="h5">
                                                {childCategory.name}
                                            </Typography>
                                        </AccordionSummaryChild>
                                        <AccordionDetailsChild>
                                            {this.isRichText(childCategory.description) ? (
                                                <RichText
                                                    disableEditor={true}
                                                    value={childCategory.description}
                                                />
                                            ) : null}
                                            {childCategory.results.map((result) => (
                                                <TestResultSingleRow
                                                    key={result.id}
                                                    result={result}
                                                />
                                            ))}
                                        </AccordionDetailsChild>
                                    </AccordionChild>);
                            }
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        ));
    }

    renderResultNoCategoryItems(noCategoryItems) {
        return (
            <Accordion defaultExpanded>
                <AccordionSummaryParent
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h4">
                        {i18n.t('text.noCategory')}
                    </Typography>
                </AccordionSummaryParent>
                <AccordionDetails>
                    <Box ml={2}>
                        {noCategoryItems.map((result) => (
                            <TestResultSingleRow
                                key={result.id}
                                result={result}
                            />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    }

    renderPromoteInfo() {
        return (
            <AppBox
                title={i18n.t('text.promoteTestResults')}
                styles={{
                    "display": 'flex',
                    "justifyContent": 'space-between',
                    "alignItems": 'flex-start',
                    "color": (theme) => theme.palette.grey[700], // theme.palette[color].darker,
                    "bgcolor": (theme) => theme.palette.grey[200], // theme.palette[color].lighter,
                    '@media (max-width: 600px)': {
                        flexDirection: 'column'
                    }
                }}
            >
                <Link
                    href="https://fims-medizin.ch/tap-diagnostik/"
                    target="_blank"
                    underline="hover"
                >
                    <Typography component="div">
                        <img
                            src="/assets/images/promoteImage.png"
                            alt="promoteImg"
                            style={{
                                maxHeight: '180px',
                                cursor: 'pointer'
                            }}
                        />
                    </Typography>
                </Link>
            </AppBox>
        );
    }

    render() {
        if (!this.state.testResultData) {
            return <></>;
        }
        const currentRole = localStorage.role;

        const testResults = this.state.testResultData
            ? this.state.testResultData.testResults
            : [];
        const noCategoryItems = testResults.filter(
            (result) => !result.referenceInfo.referenceInfoCategory
        );

        return (
            <>
                <Helmet>
                    <title> Test Results | Kingnature </title>
                </Helmet>

                <Container>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ py: 2 }}
                        spacing={2}
                    >
                        <AllTestResultsSelect
                            currentRole={currentRole}
                            testId={this.state.testResultData.id}
                        />
                    </Stack>

                    {testResults.length > 0 && (
                        <>
                            <div>
                                {this.renderResultCategoryItems(
                                    this.sortCategories(
                                        this.categorizeTestResults(testResults)
                                    )
                                )}
                                {noCategoryItems.length > 0 && this.renderResultNoCategoryItems(
                                    noCategoryItems
                                )}
                            </div>
                        </>
                    )}

                    {this.renderPromoteInfo()}
                </Container>
            </>
        );
    }
}
