import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Stack,
    Typography
} from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

class SuccessAlertDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            alertText: props.alertText,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen || false,
                alertText: props.alertText,
            };
        }

        return null;
    }
    render() {
        return (
            <Dialog
                open={this.state.dialogOpen}
                onClose={this.props.handleClose}
            >
                <DialogTitle>
                    <Stack direction="column" spacing={2} alignItems="center">
                        <CheckCircleOutline color="success" sx={{ fontSize: 50 }} />
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} autoFocus>
                        <Typography variant="button">OK</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SuccessAlertDialog;