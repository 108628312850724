// mui

import {
    Avatar,
    TableCell,
    Stack,
    Typography,
    MenuItem,
} from '@mui/material';

import PasswordIcon from '@mui/icons-material/Password';

// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';
import i18n from 'i18';
import ChangePassDialog from '../dialogs/ChangePassDialog';

export default class UsersPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "users";
        this.Server = server.users;
        this.searchFields = ['firstName', 'lastName', 'email'];

        this.state.changePassDialogOpen = false;
        //this.setState({changePassDialogOpen: false});

        this.Head = [
            { id: 'firstName', label: 'firstName', alignRight: false, cell: 'Text' },
            { id: 'lastName', label: 'lastName', alignRight: false, cell: 'Text' },
            { id: 'role', label: 'role', alignRight: false, cell: 'Items', items: ['admin', 'reseller', 'therapist', 'user'] },
            { id: 'email', label: 'email', alignRight: false, cell: 'Text' },
            { id: 'gender', label: 'gender', alignRight: false, cell: 'Items', items: ['male', 'female'] },
            { id: 'birthDate', label: 'birthDate', alignRight: false, cell: 'Date' },
            { id: 'isActive', label: 'isActive', alignRight: false, cell: 'Status' },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];

    }


    renderAvatarCell(value) {
        return (
            <TableCell component="th" scope="row" padding="none">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar alt={value} />
                    <Typography variant="subtitle2" noWrap>
                        {value}
                    </Typography>
                </Stack>
            </TableCell>
        );
    }


    renderCustomMenuItems() {
        return (
            <MenuItem onClick={() => this.openChangePassDialog()}>
                <PasswordIcon sx={{ mr: 2 }} />
                {i18n.t("buttons.changePass")}
            </MenuItem>
        );
    }


    openChangePassDialog() {
        this.setState({ changePassDialogOpen: true });
    }

    renderCustom() {
        return (
            <ChangePassDialog 
                dialogOpen={this.state.changePassDialogOpen} 
                userId={this.state.menuClickItemId} 
                onClose={() => this.setState({ changePassDialogOpen: false })} 
            />
        );
    }

}
