import React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { formatError } from 'utils/formatError';

// @mui
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Alert,
    AlertTitle,
    MenuItem,
    Typography,
    Box
} from '@mui/material';

import i18n from 'i18';
import server from 'server';
import LabBarcodeScanner from 'components/scanner/LabBarcodeScanner';
import { AppDataContext } from '../../context/Context';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

export default class TestSerialInputDialog extends React.Component {
    onClose = () => {};
    onSave = () => {};

    constructor(props) {
        super(props);

        let currentUser = JSON.parse(localStorage.user);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            dialogData: {},
            alertText: null,
            scannerOpen: true,
            loading: false,
            hasGender:
                currentUser.gender === 'male' ||
                currentUser.gender === 'female',
            hasAge: currentUser.birthDate !== null,
            step: 1 // 1,2
        };

        this.onClose = props.onClose;
        this.onSave = props.onSave;
    }

    static contextType = AppDataContext;

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen || false,
                dialogData: {},
                alertText: null
            };
        }

        return null;
    }

    handleDialogCancel = (event, name) => {
        this.onClose();
    };

    handleDialogSave = async (event, name) => {
        this.setState({ loading: true });
        let data = this.state.dialogData;
        console.log(data);

        let result = await server.tests.addUserTest(data);
        console.log(result);

        if (result.error === 'OK') {
            this.setState({ loading: false, step: 1 });
            this.onSave(this.state.dialogData);
            this.context.update({
                userInfo: {
                    ...this.context.getState().userInfo,
                    birthDate: data.birthDate,
                    gender: data.gender
                }
            });
            this.onClose();
        } else {
            this.setState({
                loading: false,
                step: 1,
                alertText: formatError(result)
            });
        }
    };

    handleDialogClose = (event, name) => {
        // what to do if we have data changed and dialog is closing
    };

    handleSerialValueChanged(value) {
        let data = this.state.dialogData;

        data.serial = value;
        this.setState({ dialogData: data, scannerOpen: false });

        console.log(data);
    }

    handleGenderChanged(event) {
        let data = this.state.dialogData;
        data.gender = event.target.value;
        this.setState({ dialogData: data });
    }

    handleBirthDateChanged(evMoment) {
        let data = this.state.dialogData;
        data.birthDate = evMoment.format('YYYY-MM-DD');
        this.setState({ dialogData: data });
        console.log(data);
    }
    handleNextStep() {
        this.setState({ step: this.state.step + 1, alertText: null });
    }
    handleScanAgain() {
        let data = this.state.dialogData;
        data.serial = '';
        this.setState({ step: 1, dialogData: data, scannerOpen: true });
    }

    // https://github.com/denisfedd/react-qr-barcode-scanner
    render() {
        return (
            <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
                <DialogTitle>{i18n.t(`pages.tests.add`)}</DialogTitle>
                <DialogContent>
                    {this.state.alertText && (
                        <Alert key="alert" severity="error">
                            <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                            {this.state.alertText}
                        </Alert>
                    )}
                    {this.state.step === 1 && (
                        <Stack direction="column">
                            <Button
                                variant="contained"
                                sx={{ my: 3 }}
                                onClick={() =>
                                    this.setState({
                                        scannerOpen: !this.state.scannerOpen
                                    })
                                }
                            >
                                {i18n.t('buttons.toggleScanner')}
                            </Button>
                            {this.state.scannerOpen && (
                                <LabBarcodeScanner
                                    width={250}
                                    onUpdate={(err, result) => {
                                        if (result) {
                                            this.handleSerialValueChanged(
                                                result.text
                                            );
                                        }
                                    }}
                                />
                            )}

                            <TextField
                                margin="dense"
                                type="text"
                                fullWidth
                                value={this.state.dialogData.serial || ''}
                                label={i18n.t('columns.serial')}
                                onChange={(event) =>
                                    this.handleSerialValueChanged(
                                        event.target.value
                                    )
                                }
                            />

                            {!this.state.hasGender && (
                                <TextField
                                    select
                                    margin="dense"
                                    id="gender"
                                    name="gender"
                                    label={i18n.t('columns.gender')}
                                    value={this.state.dialogData.gender || ''}
                                    fullWidth
                                    onChange={(event) =>
                                        this.handleGenderChanged(event)
                                    }
                                >
                                    {['male', 'female'].map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {i18n.t(`enums.${item}`)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}

                            {!this.state.hasAge && (
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker
                                        id="birthDate"
                                        name="birthDate"
                                        label={i18n.t('columns.birthDate')}
                                        onChange={(event) =>
                                            this.handleBirthDateChanged(event)
                                        }
                                        value={
                                            this.state.dialogData.birthDate
                                                ? moment(
                                                    this.state.dialogData
                                                        .birthDate
                                                )
                                                : null
                                        }
                                    />
                                </LocalizationProvider>
                            )}
                        </Stack>
                    )}
                    {this.state.step === 2 && (
                        <Stack direction="column" alignItems="center">
                            <Typography variant="h6" gutterBottom>
                                {i18n.t('text.confirmSerialData')}
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {this.state.dialogData.serial}
                            </Typography>
                            <Box></Box>
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    {this.state.step === 1 && (
                        <>
                            <Button
                                disabled={this.state.loading}
                                onClick={(event) =>
                                    this.handleDialogCancel(event)
                                }
                            >
                                {i18n.t('buttons.cancel')}
                            </Button>
                            <Button
                                disabled={!this.state.dialogData.serial}
                                onClick={() => this.handleNextStep()}
                            >
                                {i18n.t('buttons.next')}
                            </Button>
                        </>
                    )}
                    {this.state.step === 2 && (
                        <>
                            <Button onClick={() => this.handleScanAgain()}>
                                {i18n.t('buttons.scanAgain')}
                            </Button>
                            <LoadingButton
                                loading={this.state.loading}
                                variant="outlined"
                                onClick={() => this.handleDialogSave()}
                            >
                                {i18n.t('buttons.confirm')}
                            </LoadingButton>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}
