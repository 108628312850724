import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import {
    Card,
    CardHeader,
    Box,
    List,
    ListItem,
    Button,
    ListItemText,
    Stack,
    Typography,
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput,
    FormControl,
    InputLabel,
    TablePagination
} from '@mui/material';

import Label from 'components/label/Label';
import i18n from 'i18';
import { fDate } from 'utils/formatTime';
import useResponsive from 'hooks/useResponsive';
import helper from '../../utils/helper';
import Preloader from '../../components/Preloader';
import PreviewIcon from '@mui/icons-material/Preview';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import useMediaQuery from '@mui/material/useMediaQuery';
import iconStatus from '../../components/icon-tooltip/IconStatus';

AppTests.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};


export default function AppTests({
    title,
    subheader,
    data,
    showUsers,
    users,
    ...other
}) {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const isSmallMobile = useMediaQuery('(max-width:375px)');
    const navigate = useNavigate();
    const [personName, setPersonName] = useState([]);

    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    let filteredData;

    const rowsPerPage = 5;

    const pageData = data
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : null;

    if (data && personName.length > 0) {
        filteredData = pageData.filter(
            (row) => personName.indexOf(row.user.id) > -1
        );
    } else {
        filteredData = pageData;
    }

    const showSerialNumber = useResponsive('up', 'md');

    const renderLabel = (item) => {
        return (
            <Label
                color={item.status === 'result_received' ?
                    'success' : 'error' }>
                {i18n.t('enums.' + item.status)}
            </Label>
        );
    };

    const renderActions = (item) => {
        return (
            <>
                {item.status ===
                    'result_received' && (
                    <>
                        {showUsers && (
                            <Button
                                onClick={() =>
                                    window.open(
                                        '/api/tests/download/' +
                                            item.id
                                    )
                                }
                            >
                                { isMobile ? <PictureAsPdfIcon color="action"/> : 'PDF'}
                            </Button>
                        )}
                        <Button
                            onClick={() =>
                                navigate(
                                    '/app/user-test-results/' +
                                        item.id
                                )
                            }
                        >
                            { isMobile ? <PreviewIcon color="action"/> : i18n.t('buttons.showDetails')}
                        </Button>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Card
                sx={{
                    minHeight: 400,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                {...other}
            >
                <CardHeader
                    sx={isMobile ? { alignItems: 'flex-start', flexDirection: 'column' } : { alignItems: 'center' }}
                    title={title}
                    subheader={subheader}
                    action={
                        showUsers &&
                        users !== null && (
                            <FormControl
                                sx={{ m: 1, minWidth: 300, maxWidth: 500 }}
                            >
                                <InputLabel id="demo-multiple-checkbox-label">
                                    {i18n.t('dashboard.filterUsers')}
                                </InputLabel>
                                <Select
                                    sx={{ minWidth: 120 }}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={personName}
                                    onChange={(e) =>
                                        setPersonName(e.target.value)
                                    }
                                    input={
                                        <OutlinedInput
                                            label={i18n.t(
                                                'dashboard.filterUsers'
                                            )}
                                        />
                                    }
                                    renderValue={(selected) =>
                                        selected
                                            .map(
                                                (id) =>
                                                    users.filter(
                                                        (user) => user.id === id
                                                    )[0].title
                                            )
                                            .join(', ')
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            <Checkbox
                                                checked={
                                                    personName.indexOf(
                                                        user.id
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={user.title}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )
                    }
                />

                {filteredData && (
                    <Box sx={{ p: 3, pb: 1, minHeight: 300 }} dir="ltr">
                        <List>
                            {filteredData.map((item) => {
                                return (
                                    <ListItem
                                        key={item.id}
                                        secondaryAction={renderActions(item)}
                                    >
                                        <ListItemText>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{ alignItems: 'center',
                                                    ...(showUsers ? { paddingRight: 12 } : isMobile ? { paddingRight: 0 } : { paddingRight: 6 }),
                                                }}
                                            >
                                                <Typography>
                                                    { fDate(item.dateCreated) }
                                                </Typography>
                                                {showUsers && (
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {item.user.firstName +
                                                            ' ' +
                                                            item.user.lastName}
                                                    </Typography>
                                                )}
                                                {showSerialNumber && (
                                                    <Typography>
                                                        [{item.serial}]
                                                    </Typography>
                                                )}
                                                {
                                                    isMobile
                                                        ? isSmallMobile
                                                            ? iconStatus(item.status)
                                                            : showUsers ? iconStatus(item.status) : renderLabel(item)
                                                        : renderLabel(item)
                                                }


                                            </Stack>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>

                        {data && data.length === 0 && (
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    pb: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                dir="ltr"
                            >
                                <Typography
                                    variant="body2"
                                    sx={{ color: '#637381' }}
                                >
                                    {i18n.t('dashboard.noTestsAvailable')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {!data && <Preloader />}

                <TablePagination
                    component="div"
                    labelDisplayedRows={helper.labelDisplayedRows}
                    rowsPerPageOptions={[]}
                    count={data ? data.length : 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                />
            </Card>
        </>
    );
}
