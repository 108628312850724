import React from 'react';
import QRCode from 'react-qr-code';

// @mui
import {
    Button,
    Typography,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Stack,

    Alert,
    AlertTitle,
} from '@mui/material';

import server from 'server';
import i18n from 'i18';
import { formatError } from 'utils/formatError';


export default class ShowInviteCodeDialog extends React.Component {

    onClose = () => { };

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            inviteCode: null,
            alertText: null,
        };

        this.onClose = props.onClose;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen || false,
            };
        }

        return null;
    }

    async refreshInviteCode() {
        server.userInviteCodes.getInviteCode().then((result) => {
            if (result.error !== "OK") {
                this.setState({ alertText: formatError(result) });
            } else {
                this.setState({ inviteCode: result.data.inviteCode });
            }
        });
    }

    render() {

        if (this.state.dialogOpen && !this.state.inviteCode) {
            this.refreshInviteCode();
        }
        
        return (
            <Dialog open={this.state.dialogOpen}>
                <DialogTitle>{i18n.t(`dialogs.showInviteCode`)}</DialogTitle>
                <DialogContent>
                    {
                        this.state.alertText && (
                            <Alert key="alert" severity="error">
                                <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                                {this.state.alertText}
                            </Alert>
                        )
                    }
                    {
                        this.state.inviteCode &&
                        (
                            
                            <Stack direction="column">
                                <QRCode value={this.state.inviteCode} />
                                <Typography>{this.state.inviteCode}</Typography>
                            </Stack>
                        )
                    }
                    {
                        !this.state.inviteCode &&
                        (
                            <Typography>{i18n.t("text.loading")}</Typography>
                        )
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onClose()}>{i18n.t("buttons.close")}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}
