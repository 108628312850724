import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import React from 'react';

export const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    mx: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
        maxWidth: theme.breakpoints.values.sm,
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '800px',
    }
}));