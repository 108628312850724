// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class AnnouncementsPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "notifications";
        this.Server = server.notifications;
        // this.searchFields = ['name', 'parentCategory.name' ];


        this.Head = [
            { id: 'title', label: 'title', alignRight: false, cell: 'Text' },
            { id: 'description', label: 'description', alignRight: false, cell: 'Text' },
            { id: 'richText', label: 'richText', alignRight: false, cell: 'RichText' },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];


    }

}
