import RequestAbstract from "./abstract";

export default class Auth extends RequestAbstract {

    updateLocalStorageUser(result) {
        if (result.error === "OK" && result.user) {
            localStorage.user = JSON.stringify(result.user);
            localStorage.role = result.user.role;
            localStorage.token = result.session;
        }
    }

    accountInfo() {
        if (!localStorage.user) {
            return {};
        }
        
        let user = JSON.parse(localStorage.user);
        return {
            displayName: user.firstName + " " + user.lastName,
            email: user.email,
        };
    }

    async logoutUser() {
        return this.get("logout");
    }
    
    async loginUser(username, password) {
        let result = await this.postForm("process-login", { username, password });
        this.updateLocalStorageUser(result);
        return result;
    } 

    async loginWithGoogle(clientId, credential, role) {
        let result = await this.post("api/auth/login-with-google", { clientId, credential, role });
        this.updateLocalStorageUser(result);
        return result;
    }

    async register(data) {
        let result = await this.post("api/auth/register", data);
        this.updateLocalStorageUser(result);
        return result;
    }

    async forgot(data) {
        let result = await this.post("api/auth/forgot", data);
        this.updateLocalStorageUser(result);
        return result;
    }

}
