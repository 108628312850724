import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Link } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
// server
import i18n from 'i18';

import ShowInviteCodeDialog from 'pages/dialogs/ShowInviteCodeDialog';
import LinkInviteCodeDialog from 'pages/dialogs/LinkInviteCodeDialog';
import ChangePassDialog from 'pages/dialogs/ChangePassDialog';
import UpdateProfileDialog from '../pages/dialogs/UpdateProfileDialog';
import { AppDataContext } from '../context/Context';
import AddAnnouncementDialog from '../pages/dialogs/AddAnnouncementDialog';


const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));


let UsersHead = [
    { id: 'firstName', label: 'firstName', alignRight: false, cell: 'Text' },
    { id: 'lastName', label: 'lastName', alignRight: false, cell: 'Text' },
    { id: 'gender', label: 'gender', alignRight: false, cell: 'Items', items: ['male', 'female'] },
    { id: 'birthDate', label: 'birthDate', alignRight: false, cell: 'Date' },
];

export default function AccountPopover() {

    const [open, setOpen] = useState(null);
    const [showInviteCodeDialogOpen, setShowInviteCodeDialogOpen] = useState(false);
    const [linkInviteCodeDialogOpen, setLinkInviteCodeDialogOpen] = useState(false);
    const [changePassDialogOpen, setChangePassDialogOpen] = useState(false);
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const [announcementDialogOpen, setAnnouncementDialogOpen] = useState(false);

    const context = useContext(AppDataContext);

    const navigate = useNavigate();

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        // TODO fix the menu reload with roles permissions
        document.location = "/auth/login";
        //navigate("/auth/login", {replace: true});
    };


    const isDesktop = useResponsive('up', 'lg');

    const currentUserData = context.getState().userInfo;
    const account = {
        displayName: currentUserData.firstName + " " + currentUserData.lastName,
        email: currentUserData.email,
    };
    const role = localStorage.role ? localStorage.role : "";

    if (role === "therapist") {
        UsersHead = [
            { id: 'company', label: 'company', alignRight: false, cell: 'Text' },
            ...UsersHead,
        ];
    }

    return (
        <>
            <ShowInviteCodeDialog
                dialogOpen={showInviteCodeDialogOpen}
                onClose={() => setShowInviteCodeDialogOpen(false)}
            />
            <LinkInviteCodeDialog
                dialogOpen={linkInviteCodeDialogOpen}
                onClose={() => setLinkInviteCodeDialogOpen(false)}
            />
            <ChangePassDialog
                dialogOpen={changePassDialogOpen}
                userId={currentUserData.id}
                onClose={() => setChangePassDialogOpen(false)}
            />

            <UpdateProfileDialog
                dialogOpen={profileDialogOpen}
                dialogData={currentUserData}
                Head={UsersHead}
                onSave={(data) => {
                    setProfileDialogOpen(false);
                    // TODO update localStorage user information here or reload page
                }}
                onClose={() => setProfileDialogOpen(false)}
            />

            <AddAnnouncementDialog
                dialogOpen={announcementDialogOpen}
                onClose={() => setAnnouncementDialogOpen(false)}
            />


            {isDesktop ? (

                <Box sx={{ cursor: "pointer", mb: 5, mx: 2.5 }}>
                    <Link underline="none" onClick={handleOpen}>
                        <StyledAccount>
                            <Avatar alt="AB" />

                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {account.displayName}
                                </Typography>

                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {account.role}
                                </Typography>
                            </Box>
                        </StyledAccount>
                    </Link>
                </Box>

            ) : (

                <IconButton
                    onClick={handleOpen}
                    sx={{
                        p: 0,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                            },
                        }),
                    }}
                >
                    <Avatar alt="AB" />
                </IconButton>

            )}


            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        "p": 0,
                        "mt": 1.5,
                        "ml": 0.75,
                        "width": 220,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {account.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {account.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>

                    <MenuItem key="home" onClick={() => {
                        handleClose();
                        navigate("/app");
                    }}>
                        {i18n.t('profile.home')}
                    </MenuItem>

                    <MenuItem key="profile" onClick={() => {
                        handleClose();
                        setProfileDialogOpen(true);
                    }}>
                        {i18n.t('profile.profile')}
                    </MenuItem>

                    {
                        role === "admin" && (
                            <MenuItem key="addAnnouncement" onClick={() => {
                                handleClose();
                                setAnnouncementDialogOpen(true);
                            }}>
                                {i18n.t('profile.addAnnouncement')}
                            </MenuItem>
                        )
                    }

                    <MenuItem key="changePass" onClick={() => {
                        handleClose();
                        setChangePassDialogOpen(true);
                    }}>
                        {i18n.t('buttons.changePass')}
                    </MenuItem>

                    {
                        role === "user" && (
                            <MenuItem key="createInviteCode" onClick={() => {
                                handleClose();
                                setShowInviteCodeDialogOpen(true);
                            }}>
                                {i18n.t('profile.createInviteCode')}
                            </MenuItem>
                        )
                    }

                    {
                        (role === "therapist" || role === "reseller") && (
                            <MenuItem key="linkUserByInviteCode" onClick={() => {
                                handleClose();
                                setLinkInviteCodeDialogOpen(true);
                            }}>
                                {i18n.t('profile.linkUserByInviteCode')}
                            </MenuItem>
                        )
                    }

                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    {i18n.t('profile.logout')}
                </MenuItem>
            </Popover>
        </>
    );
}
