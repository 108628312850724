import RequestAbstract from "../abstract";

export default class Users extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/users";
    }


    async metaInfo() {
        return this.get("api/users/meta-info");
    } 

    async dashboardInfo() {
        let result = await this.get("api/users/dashboard-info");
        if (result.error === "OK") {
            return result.data;
        } 
        return {};
        
    }

    async changePass(userId, newPass) {
        return this.post("api/users/change-pass", { userId, newPass });
    }

    async updateProfile(info) {
        return this.post("api/users/update-profile", info);
    }

}
