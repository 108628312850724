import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import { Container, } from '@mui/material';

import server from 'server';
import AdminDashboardContent from './dashboard/AdminDashboardContent';
import TherapistDashboardContent from './dashboard/TherapistDashboardContent';
import UserDashboardContent from './dashboard/UserDashboardContent';
import helper from 'utils/helper';

export default class DashboardAppPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {

        server.users.dashboardInfo().then(data => {
            this.setState({ data: data });
        });

    }

    render() {

        let role = this.state.data ? localStorage.role : "";

        return (
            <>
                <Helmet>
                    <title>{helper.formPageTitle("menu.dashboard")}</title>
                </Helmet>

                <Container maxWidth="xl">

                    {role === "admin" && <AdminDashboardContent {...this.props} data={this.state.data} />}
                    {role === "therapist" && <TherapistDashboardContent {...this.props} data={this.state.data} />}
                    {role === "user" && <UserDashboardContent {...this.props} data={this.state.data} />}

                </Container>
            </>
        );
    }
}
