import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
    Box,
    List,
    Badge,
    Button,
    Tooltip,
    Divider,
    Popover,
    Typography,
    IconButton,
    ListItemText,
    ListSubheader,
    ListItemButton
} from '@mui/material';
// utils
import { fToNow } from 'utils/formatTime';
// components
import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar';
// hooks
import { useStateSelector, useStore } from '../context/StateSelectors';
// translations
import i18n from 'i18';
import PreviewAnnouncementDialog from '../pages/dialogs/PreviewAnnouncementDialog';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    const store = useStore();
    const notifications = useStateSelector((state) => state.notifications);


    const [seeAll, setSeeAll] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [currentNotification, setCurrentNotification] = useState(null);

    const totalUnRead = notifications.filter(
        (item) => item.isUnRead === true
    ).length;

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const handleOpenPreview = (notification) => {
        setCurrentNotification(notification);
        setPreviewOpen(true);
    };
    const handleClosePreview = () => {
        handleMarkAsRead(currentNotification);
        setCurrentNotification(null);
        setPreviewOpen(false);
    };
    const handleMarkAsRead = (notification) => {
        const id = notification.id;
        // server.notifications.markAsRead([id]);
        localStorage.notifications = JSON.stringify(notifications.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isUnRead: false
                };
            }
            return item;
        }));
        store.update({
            notifications: notifications.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isUnRead: false
                    };
                }
                return item;
            })
        });
    };

    const handleMarkAllAsRead = () => {
        // server.notifications.markAsRead(notifications.map((notification) => notification.id));
        localStorage.notifications = JSON.stringify(notifications.map((notification) => ({
            ...notification,
            isUnRead: false
        })));
        store.update({
            notifications: notifications.map((notification) => ({
                ...notification,
                isUnRead: false
            }))
        });
    };

    return (
        <>
            <PreviewAnnouncementDialog
                open={previewOpen}
                data={currentNotification}
                onClose={() => handleClosePreview()}
            />
            <IconButton
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" />
                </Badge>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        mt: 1.5,
                        ml: 0.75,
                        width: 360
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: 2,
                        px: 2.5
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                            {i18n.t('notifications.title')}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary' }}
                        >
                            {i18n.t('notifications.totalUnread', {
                                total: totalUnRead
                            })}
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title={i18n.t('notifications.markAllAsRead')}>
                            <IconButton
                                color="primary"
                                onClick={handleMarkAllAsRead}
                            >
                                <Iconify icon="eva:done-all-fill" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                    {seeAll ? (
                        <List
                            disablePadding
                            subheader={
                                <ListSubheader
                                    disableSticky
                                    sx={{
                                        py: 1,
                                        px: 2.5,
                                        typography: 'overline'
                                    }}
                                >
                                    {i18n.t('notifications.all')}
                                </ListSubheader>
                            }
                        >
                            {notifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    handleOpenPreview={handleOpenPreview}
                                />
                            ))}
                        </List>
                    ) : (
                        <>
                            <List
                                disablePadding
                                subheader={
                                    <ListSubheader
                                        disableSticky
                                        sx={{
                                            py: 1,
                                            px: 2.5,
                                            typography: 'overline'
                                        }}
                                    >
                                        {i18n.t('notifications.new')}
                                    </ListSubheader>
                                }
                            >
                                {notifications
                                    .slice(0, 2)
                                    .map((notification) => (
                                        <NotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            handleOpenPreview={
                                                handleOpenPreview
                                            }
                                        />
                                    ))}
                            </List>

                            {notifications.length > 2 && (
                                <List
                                    disablePadding
                                    subheader={
                                        <ListSubheader
                                            disableSticky
                                            sx={{
                                                py: 1,
                                                px: 2.5,
                                                typography: 'overline'
                                            }}
                                        >
                                            {i18n.t('notifications.beforeThat')}
                                        </ListSubheader>
                                    }
                                >
                                    {notifications
                                        .slice(2, 5)
                                        .map((notification) => (
                                            <NotificationItem
                                                key={notification.id}
                                                notification={notification}
                                                handleOpenPreview={
                                                    handleOpenPreview
                                                }
                                            />
                                        ))}
                                </List>
                            )}
                        </>
                    )}
                </Scrollbar>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ p: 1 }}>
                    <Button
                        fullWidth
                        disableRipple
                        onClick={() => setSeeAll(!seeAll)}
                    >
                        {seeAll
                            ? i18n.t('notifications.seeLess')
                            : i18n.t('notifications.seeAll')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.number,
        isUnRead: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        handleOpenPreview: PropTypes.func
    })
};

function NotificationItem({ notification, handleOpenPreview }) {
    const { title } = renderContent(notification);

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(notification.isUnRead && {
                    bgcolor: 'action.selected'
                })
            }}
            id={notification.id}
            onClick={() => handleOpenPreview(notification)}
        >
            {/*<ListItemAvatar>*/}
            {/*    <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>*/}
            {/*</ListItemAvatar>*/}
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled'
                        }}
                    >
                        <Iconify
                            icon="eva:clock-outline"
                            sx={{ mr: 0.5, width: 16, height: 16 }}
                        />
                        {fToNow(notification.dateCreated)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2">
            {notification.title}
            <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.secondary' }}
            >
                &nbsp; {noCase(notification.description)}
            </Typography>
        </Typography>
    );

    return {
        title
    };
}
