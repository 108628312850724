import React from 'react';

// @mui
import {
    Button,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Alert,
} from '@mui/material';

import i18n from 'i18';
import { LoadingButton } from '@mui/lab';


export default class ConfirmDialog extends React.Component {

    onCancel = () => { };
    onConfirm = () => { };

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            alertText: props.alertText,
            alertTitle: props.alertTitle,
            loading: false,
        };

        this.onCancel = props.onCancel;
        this.onConfirm = props.onConfirm;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen,
                alertText: props.alertText,
                alertTitle: props.alertTitle,
                loading: false,
            };
        }

        return null;
    }

    handleDialogCancel = (event, name) => {
        this.onCancel();
    };

    handleDialogConfirm = async (event, name) => {
        this.setState({ loading: true });
        await this.onConfirm();
        this.setState({ loading: false });
    };

    handleDialogClose = (event, name) => {
        this.onCancel();
    };

    render() {
        
        return (
            <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
                <DialogTitle>{this.state.alertTitle}</DialogTitle>
                <DialogContent>

                    <Alert key="alert" severity="warning">
                        {this.state.alertText}
                    </Alert>

                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.loading} onClick={(event) => this.handleDialogCancel(event)}>{i18n.t("buttons.cancel")}</Button>
                    <LoadingButton loading={this.state.loading} onClick={(event) => this.handleDialogConfirm(event)}>{i18n.t("buttons.confirm")}</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

}
