import React from 'react';
import { Container, Typography } from "@mui/material";

function PrivacyPolicy() {
    return (

        <Container>
            <Typography align={"justify"}>
                <Typography variant="h4" gutterBottom align={"center"} mt={5}>
                    Terms of service
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Datenschutzerklärung
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Datenschutz auf einen Blick
                </Typography>
                <div>
                    <Typography variant="body1">
                        Allgemeine Hinweise
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Datenerfassung auf unserer Website
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Datenverarbeitung auf dieser Website erfolgt durch kingnature. Unsere Kontaktdaten finden Sie im Impressum oder unter Kontakt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wie erfassen wir Ihre Daten?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website besuchen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wofür nutzen wir Ihre Daten?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Welche Rechte haben Sie bezüglich Ihrer Daten?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Analyse-Tools und Tools von Drittanbietern
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    2. Allgemeine Hinweise und Pflichtinformationen
                </Typography>
                <div>
                    <Typography variant="body1" gutterBottom>
                        Datenschutz
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Hinweis zur verantwortlichen Stelle
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                    </Typography>
                    <Typography variant="body1" >
                        kingnature AG
                    </Typography>
                    <Typography variant="body1" >
                        Staubstrasse 1
                    </Typography>
                    <Typography variant="body1" >
                        8038 Zürich
                    </Typography>
                    <Typography variant="body1" >
                        Telefon: +41 (0)44 271 22 00
                    </Typography>
                    <Typography variant="body1" >
                        E-Mail: info@kingnature.ch
                    </Typography>
                    <Typography variant="body1" gutterBottom mt={1}>
                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Widerruf Ihrer Einwilligung zur Datenverarbeitung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Recht auf Datenübertragbarkeit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        SSL- bzw. TLS-Verschlüsselung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Verschlüsselter Zahlungsverkehr auf dieser Website
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschliesslich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Auskunft, Sperrung, Löschung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Widerspruch gegen Werbe-Mails
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    3. Datenerfassung auf unserer Website
                </Typography>
                <div>
                    <Typography variant="body1" gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen sowie das automatische Löschen der Cookies beim Schliessen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden gespeichert aufgrund des berechtigten Interesses des Websitebetreibers an der technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Server-Log-Dateien
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ marginLeft: 4 }}>
                        <ul style={{ listStyle: 'square' }}>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                        <br />
                        Die Datenverarbeitung erfolgt gemäss gesetzlichen Bestimmungen, welche die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Massnahmen gestatten.
                        <br />
                        Kontaktformular
                        <br />
                        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        <br />
                        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschliesslich auf Grundlage Ihrer Einwilligung. Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                        <br />
                        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                        <br />
                        Registrierung auf dieser Website
                        <br />
                        Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
                        <br />
                        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
                        <br />
                        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung. Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                        <br />
                        Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf unserer Website registriert sind und werden anschliessend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                        <br />
                        Kommentarfunktion auf dieser Website
                        <br />
                        Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte Nutzername gespeichert.
                        <br />
                        Speicherung der IP-Adresse
                        <br />
                        Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare auf unserer Seite nicht vor der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu können.
                        <br />
                        Abonnieren von Kommentaren
                        <br />
                        Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese Funktion jederzeit über einen Link in den Info-Mails abbestellen. Die im Rahmen des Abonnierens von Kommentaren eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie diese Daten für andere Zwecke und an anderer Stelle (z.B. Newsletterbestellung) an uns übermittelt haben, verbleiben die jedoch bei uns.
                        <br />
                        Speicherdauer der Kommentare
                        <br />
                        Die Kommentare und die damit verbundenen Daten (z.B. IP-Adresse) werden gespeichert und verbleiben auf unserer Website, bis der kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden müssen (z.B. beleidigende Kommentare).
                        <br />
                        Rechtsgrundlage
                        <br />
                        Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung. Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                        <br />
                        Verarbeiten von Daten (Kunden- und Vertragsdaten)
                        <br />
                        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage gesetzlicher Bestimmungen, welche die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Massnahmen gestatten. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
                        <Typography variant="body1" gutterBottom>
                            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und Warenversand
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der Lieferung der Ware betrauten Unternehmen oder das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Grundlage für die Datenverarbeitung sind gesetzliche Bestimmungen, welche die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Massnahmen gestatten.
                        </Typography>

                    </Typography>

                    <Typography variant="body1" gutterBottom>

                    </Typography>
                    <Typography variant="body1" gutterBottom>

                    </Typography>

                </div>

                <Typography variant="h6" gutterBottom>
                    4. Art und Weise der Datenbearbeitung und Massnahmen zur Datensicherheit
                </Typography>
                <div>
                    <Typography variant="body1" gutterBottom>
                        4.1. Auswertung der Daten für den Kunden
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Blutentnahme via TAP-Device wird als Selbsttest selbständig durchgeführt. Die Blutproben werden von Kunden im beiliegenden dafür vorgesehenen Versandbeutel per Post an das Labor gesendet. Die Auswertung / Laboranalyse wird durch die SwissAnalysis GmbH durchgeführt. Diese ist ein etabliertes Labor für die klinische Diagnostik und als Speziallabor landesweit bekannt.
                        Je nach gewähltem Profil werden die Spiegel von bis zu ca. 40 Biomarkern im Blut gemessen (z.B. Vitamin D, Zink, Selen, usw.). Allfällige Mikronährstoffmängel werden dabei aufgezeigt und gespeichert. Die so gewonnenen Daten werden allenfalls mit den dem Kunden empfohlenen und von ihm erworbenen Produkten verknüpft, die die aufgezeigten Mängel womöglich beseitigen können.
                        Der Kunde kann alle seine Biomarker-Spiegel auf Basis des gewählten Profils sehen. Ausser der kingnature AG und dem Labor hat niemand Einsicht in diese Daten. Dem Kunden steht es jedoch frei, dem Reseller oder Therapeuten Einblick in seine Daten zu gewähren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.2. Datenübermittlung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Datentransfer zwischen dem Labor und unserer Datenbank erfolgt gemäß dem HL7-Standard (Health Level Seven) und gewährleistet eine nahtlose und strukturierte Kommunikation. HL7 erleichtert den sicheren Austausch von gesundheitsbezogenen Informationen, einschließlich Bluttestergebnissen, und sorgt gleichzeitig für die Integrität der Daten und die Kompatibilität zwischen den Systemen.

                        Sämtliche Daten werden in einem hochsicheren Rechenzentrum in der Schweiz gehostet, das höchsten Datenschutz- und Sicherheitsstandards entspricht.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        4.3. Wann und durch wen werden die Blutproben vernichtet?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Das schweizerische Labor behandelt (vernichtet) die Blutproben gemäss den anwendbaren abfallrechtlichen Bestimmungen in der Schweiz.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        4.4. Auswertung der Daten für Forschung und Statistik
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Das Labor analysiert die Blutproben und ermittelt und speichert die Biomarker auch für den Zweck Forschung und Statistik.
                        In anonymisierter Form können Erkenntnisse aus der Auswertung der Daten Versicherungen und Partnern im Gesundheitssystem, welche im Bereich der Gesundheitsprävention tätig sind, bekanntgegeben werden, um damit bessere Entscheidungen im Bereich der Gesundheitsprävention treffen zu können. Die Anonymisierung der Daten erfolgt vor ihrer Bekanntgabe.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    5. Empfänger oder Kategorien von Empfängern, denen Personendaten bekanntgegeben werden
                </Typography>

                <div>
                    <Typography variant="body1" gutterBottom>
                        Zu den obengenannten Zwecken werden Kundendaten an ein Labor bekanntgegeben.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Erkenntnisse aus der Auswertung der Daten können Versicherungen und Partnern im Gesundheitssystem bekanntgegeben werden, wie oben beschrieben.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Kundendaten können einer Rechtsanwaltskanzlei oder einem Inkassobüro bekanntgegeben werden, soweit dies zur Durchsetzung oder Verteidigung von Ansprüchen der kingnature AG erforderlich ist.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Eine Bekanntgabe von Daten an Reseller oder Therapeuten erfolgt nur nach ausdrücklicher Zustimmung des Kunden.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    6. Risiken der Bearbeitung von Gesundheitsdaten
                </Typography>

                <div>
                    <Typography variant="body1" gutterBottom>
                        Bei den bearbeiteten Blutdaten handelt es sich um Gesundheitsdaten. Gesundheitsdaten sind besonders schützenswert, weil eine Verletzung der Datensicherheit die Persönlichkeit und die Grundrechte der betroffenen Personen besonders stark beeinträchtigen kann. Trotz sorgfältiger Datenschutzmassnahmen verbleiben stets Risiken für die Datensicherheit.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        So kann niemals zur Gänze ausgeschlossen werden, dass unbefugte Personen von den Gesundheitsdaten Kenntnis erlangen, was mehrere Risiken zur Folge haben könnte:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        1. Diskriminierung, z.B. durch Bekanntwerden ansteckender oder stigmatisierender Erkrankungen;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2. Finanzieller Verlust, z.B. durch Verlust des Beschäftigungsverhältnisses durch Bekanntwerden einer chronischen Erkrankung, welche z.B. häufige längerfristige krankheitsbedingte Ausfalle im Berufsleben beinhaltet;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3. Rufschädigung, z.B. durch Bekanntwerden des Vorliegens einer sexuell übertragbaren Erkrankung.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ausserdem besteht stets ein Restrisiko der falschen Zuordnung von Daten und Kunden (Verwechslung). Diesfalls könnten die resultierenden Analysen unrichtig und daraufhin getroffene Massnahmen (z.B. Supplementierungen) für die Gesundheit unwirksam oder nachteilig sein.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Zuordnung der Blutproben und der Laboranalyse erfolgt durch das Labor. Dieser Prozess wird von den Kontrollbehörden überprüft.
                    </Typography>

                </div>


                <Typography variant="h6" gutterBottom>
                    7. Aufbewahrungsdauer oder Kriterien zur Festlegung dieser Dauer.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Die Daten werden so lange aufbewahrt, wie es zur Erfüllung der oben genannten Zwecke erforderlich ist. Anschliessend steht es der kingnature AG frei, die Daten für bis zu 15 Jahre zu archivieren oder sie zu löschen.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. Kategorien von Daten, die nicht bei der betroffenen Person beschafft werden
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Keine.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    9. Bekanntgabe von Daten ins Ausland
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Keine.
                </Typography>
            </Typography>

        </Container>
    );
}
export default PrivacyPolicy;
