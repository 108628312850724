import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';

import { toggleMark, withInlines } from './helpers';
import Element from './Element';
import Leaf from './Leaf';
import Toolbar from './Toolbar';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code'
};

const RichText = (props) => {
    const renderElement = useCallback((it) => <Element {...it} />, []);
    const renderLeaf = useCallback((it) => <Leaf {...it} />, []);
    const editor = useMemo(
        () => withInlines(withHistory(withReact(createEditor()))),
        []
    );

    let initialValue;
    try {
        initialValue = JSON.parse(props.value || ''); // prevent successfull json parse of null
    } catch {
        initialValue = [{ type: 'paragraph', children: [{ text: '' }] }];
    }

    if (props.disableEditor) {
        return (
            <Slate editor={editor} initialValue={initialValue}>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    readOnly
                />
            </Slate>
        );
    }

    return (
        <Slate
            onChange={(data) => props.onChange(JSON.stringify(data))}
            editor={editor}
            initialValue={initialValue}
        >
            <Toolbar />
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.label}
                spellCheck
                autoFocus
                onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                            event.preventDefault();
                            const mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    }
                }}
            />
        </Slate>
    );
};

export default RichText;
