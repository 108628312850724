import RequestAbstract from "../abstract";

export default class Tests extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/tests";
    }


    async getTestResult(id) {
        let result = await this.get("api/tests/get-extended/" + id);
        if (result.error === "OK") {
            return result.data;
        } 
        document.location = "/auth/login";
        return {};
        
    }

    async addUserTest(data) {
        return await this.loadWithAuth(this.link + "/add-user-test", {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    async addAnotherUserTest(data) {
        return await this.loadWithAuth(this.link + "/add-another-user-test", {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }
    async export(filter, sort, paging) {
        return await this.loadWithAuth(this.link + "/export", {
            method: 'POST',
            body: JSON.stringify({ filter, sort, paging })
        }, true);
    }

    async download(id) {
        return await this.loadWithAuth(this.link + "/download/" + id, {
            method: 'GET'
        }, true);
    }

}
