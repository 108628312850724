// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class UserInviteCodesPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "userInviteCodes";
        this.Server = server.userInviteCodes;
        this.searchFields = ['user.firstName', 'user.lastName', 'inviteCode'];

        this.CanDelete = ['admin'];
        this.CanUpdate = ['admin'];
        this.CanAdd = ['admin'];
    
        this.Head = [
            { id: 'userId', object: 'user', display: 'firstName+lastName', label: 'user', alignRight: false, cell: 'Select', from: 'users' },
            { id: 'inviteCode', label: 'inviteCode', alignRight: false, cell: 'Text' },
            { id: 'expireDate', label: 'expireDate', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];

    }
  
}
