import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography, Alert, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'components/iconify';
import LanguagePopover from 'popovers/LanguagePopover';
import { LoginWithGoogle } from 'sections/auth/LoginWithGoogle';
import { LoginWithApple } from 'sections/auth/LoginWithApple';
// server
import server from 'server';
import helper from 'utils/helper';
import { formatError, hasError } from 'utils/formatError';
import i18n from 'i18';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    // Autofill check https://github.com/facebook/react/issues/1159#issuecomment-1025423604
    const [wasUsernameAutofilled, setWasUsernameAutofilled] = useState(false);
    const [wasPasswordAutofilled, setWasPasswordAutofilled] = useState(false);

    useLayoutEffect(() => {
        const checkAutofilled = () => {
            const usernameAutofilled = !!document.getElementById('username')?.matches('*:-webkit-autofill');
            const passwordAutofilled = !!document.getElementById('password')?.matches('*:-webkit-autofill');
            console.log("usernameAutofilled", usernameAutofilled, "passwordAutofilled", passwordAutofilled);
            setWasUsernameAutofilled(usernameAutofilled);
            setWasPasswordAutofilled(passwordAutofilled);
        };
        // The time when it's ready is not very stable, so check few times
        setTimeout(checkAutofilled, 200);
        setTimeout(checkAutofilled, 500);
        setTimeout(checkAutofilled, 1000);
        setTimeout(checkAutofilled, 2000);
    }, []);


    const handleLoginClick = async () => {

        setError("");
        setLoading(true);

        let result = await server.auth.loginUser(username, password);
        if (result.error === "OK") {
            setLoading(false);
            document.location = "/app/dashboard";
            //navigate('/app/dashboard', { replace: true });
        } else {
            setLoading(false);
            setError(formatError(result));
        }
    };

    const handleGoogleSuccess = (google) => {
        server.auth.loginWithGoogle(google.clientId, google.credential).then((result) => {
            if (!hasError(result)) {
                if (!result.user) {
                    // user not registered, we need to select role and accept terms to register
                    navigate("/auth/login-by-google", {
                        state: {
                            clientId: google.clientId,
                            credential: google.credential,
                            ...result
                        }
                    });
                } else {
                    document.location = "/app/dashboard";
                    //navigate('/app', { replace: true });
                }
            } else {
                setError(formatError(result));
            }
        });
    };

    // ensuring old session is not used instead of new tokens
    document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    return (
        <>
            <Helmet>
                <title>{helper.formPageTitle("auth.login")}</title>
            </Helmet>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    {i18n.t("auth.loginTitle")}
                </Typography>

                <LanguagePopover forceSmall={true} />
            </Stack>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>

                <TextField
                    name="username"
                    id="username"
                    label={i18n.t("columns.email")}
                    value={username}
                    onKeyDown={ev => {
                        if (ev.key === "Enter" && password.length > 0) {
                            handleLoginClick();
                        }
                    }}
                    onChange={event => setUsername(event.target.value)}
                    InputLabelProps={{
                        shrink: wasUsernameAutofilled || username.length > 0
                    }}
                />

                <TextField
                    name="password"
                    id="password"
                    label={i18n.t("columns.password")}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleLoginClick();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: wasPasswordAutofilled || password.length > 0
                    }}
                />
            </Stack>

            <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} onClick={handleLoginClick}>
                {i18n.t("auth.login")}
            </LoadingButton>

            <LoginWithGoogle onSuccess={(result) => handleGoogleSuccess(result)} />

            <LoginWithApple />

            {error && <Alert severity="error">{error}</Alert>}

            <Divider sx={{ my: 2 }} />

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
                <Link variant="subtitle2" underline="hover" onClick={() => navigate("/auth/register")}>
                    {i18n.t("auth.newUserRegisterLink")}
                </Link>
                <Link variant="subtitle2" underline="hover" onClick={() => navigate("/auth/forgot")}>
                    {i18n.t("auth.forgotPasswordLink")}
                </Link>
            </Stack>

        </>
    );
}
