import React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';


import i18n from 'i18';

export default class DialogDateField extends React.Component {

    handleBirthDateChanged(ev) {
        const modifiedDate = moment(ev).format('YYYY-MM-DD');
        this.props.onChange(this.props.field.id, modifiedDate);
    }

    render() {
        // field, value, cache, onChange
        const locale = i18n.language === 'en' ? 'en-gb' : i18n.language;
        let translatedName = i18n.t(`columns.${this.props.field.label}`);

        return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                <DatePicker
                    sx={{ width: '100%', my: 1 }}
                    id={this.props.field.id}
                    name={this.props.field.id}
                    label={translatedName}
                    defaultValue={this.props.value ? moment(this.props.value) : null}
                    onChange={event => this.handleBirthDateChanged(event)}
                />
            </LocalizationProvider>
        );
    }
}
