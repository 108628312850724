import React from 'react';

import i18n from 'i18';

// @mui
import {
    TextField,
    MenuItem,
} from '@mui/material';

export default class DialogItemsField extends React.Component {

    onChange(event) {
        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        // field, value, cache, onChange

        let translatedName = i18n.t(`columns.${this.props.field.label}`);
        let id = this.props.field.id;
        let value = this.props.value;

        return (
            <TextField 
                select 
                margin="dense" 
                id={id} 
                name={id} 
                label={translatedName} 
                defaultValue={value ? `${value}` : ''} 
                fullWidth 
                onChange={(event) => this.onChange(event)}>
                {
                    this.props.field.items.map(item => (
                        <MenuItem key={item} value={item}>
                            {i18n.t(`enums.${item}`)}
                        </MenuItem>
                    ))
                }
            </TextField>
        );
    }
}
