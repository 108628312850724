import { Editor, Transforms, Range, Element as SlateElement } from 'slate';
import { ReactEditor } from 'slate-react';
import i18n from 'i18';
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true
    });

    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format
        };
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format
        };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};
const handleInsertLink = (editor) => {
    // eslint-disable-next-line no-alert
    const url = window.prompt(i18n.t('dialogs.richTextEnterUrl'));
    if (!url) return;

    insertLink(editor, url);
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
        if (format === 'link') {
            removeLink(editor);
        }
    } else {
        Editor.addMark(editor, format, true);
    }
    if (format === 'link' && !isActive) {
        handleInsertLink(editor);
    }
};

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format
        })
    );

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const withInlines = (editor) => {
    const {
        insertData,
        insertText,
        isInline,
        isElementReadOnly,
        isSelectable
    } = editor;

    editor.isInline = (element) =>
        ['link', 'button', 'badge'].includes(element.type) || isInline(element);

    editor.isElementReadOnly = (element) =>
        element.type === 'badge' || isElementReadOnly(element);

    editor.isSelectable = (element) =>
        element.type !== 'badge' && isSelectable(element);

    editor.insertText = (text) => {
        if (text && isLinkActive(editor)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = (data) => {
        const text = data.getData('text/plain');

        if (text && isLinkActive(editor)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

const createLinkNode = (url, text) => ({
    type: 'link',
    url,
    children: [{ text }]
});

const insertLink = (editor, url) => {
    if (!url) return;

    const { selection } = editor;
    const link = createLinkNode(url, 'New Link');

    ReactEditor.focus(editor);

    if (selection) {
        const [start] = Range.edges(selection);
        const hasText = Editor.string(editor, selection) !== '';

        // If there is a selection and it contains text, wrap the selected text in a link
        if (hasText) {
            Transforms.wrapNodes(editor, link, { at: selection, split: true });
        } else {
            // If there is an empty selection or a collapsed selection, insert the link at the cursor
            Transforms.insertNodes(editor, link, { at: start });
        }
    } else {
        // If there is no selection, insert the link at the cursor
        Transforms.insertNodes(editor, link);
    }
};
const removeLink = (editor, opts = {}) => {
    Transforms.unwrapNodes(editor, {
        ...opts,
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === 'link'
    });
};

const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === 'link'
    });
    return !!link;
};

const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === 'link'
    });
};

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : []
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export {
    toggleBlock,
    toggleMark,
    isBlockActive,
    isMarkActive,
    insertLink,
    withInlines,
    LIST_TYPES,
    TEXT_ALIGN_TYPES
};
