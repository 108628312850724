import React, {
    useMemo,
} from 'react';
import { AppStore, AppDataContext } from './Context';

export const AppDataProvider = ({ children }) => {

    const initialState = {
        permissions: {},
        userInfo: {},
        notifications: localStorage.notifications ? JSON.parse(localStorage.notifications) : [],
    };

    const store = useMemo(() => new AppStore(initialState), []);


    return (
        <AppDataContext.Provider value={store}>
            {children}
        </AppDataContext.Provider>
    );
};