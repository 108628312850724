import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

import config from 'config';

const { language, languages } = config;

const lng = languages.includes(localStorage.language) ? localStorage.language : language;

await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng,
        fallbackLng: language,
        locales: languages,
        debug: true,

        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
