import React from 'react';
import i18n from 'i18';

// @mui
import { TableCell } from '@mui/material';


import Label from '../../components/label';

export default class GridBooleanCell extends React.Component {
    render() {
        // props: value, id, field
        return (
            <TableCell key={this.props.field.id} align="left">
                {/*<Label color={this.props.value ? 'success' : 'error'}>*/}
                {/*    {this.props.value*/}
                {/*        ? <TaskAltIcon />*/}
                {/*        : <ClearIcon />*/}
                {/*    }*/}
                {/*</Label>*/}
                <Label color={this.props.value ? 'success' : 'error'}>
                    {this.props.value
                        ? i18n.t('values.yes')
                        : i18n.t('values.no')}
                </Label>
            </TableCell>
        );
    }
}
