import React from 'react';

// @mui
import {
    Button,
    TextField,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Alert,
    AlertTitle,
} from '@mui/material';

import { hasError, formatError } from 'utils/formatError';

import server from 'server';
import i18n from 'i18';
import { LoadingButton } from '@mui/lab';

export default class ChangePassDialog extends React.Component {

    onClose = () => { };

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            alertText: props.alertText,
            alertTitle: props.alertTitle,
            userId: props.userId,
            newPass: "",
            confirmNewPass: "",
            buttonClicked: false,
            loading: false,
        };

        this.onClose = props.onClose;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen,
                alertText: props.alertText,
                alertTitle: props.alertTitle,
                userId: props.userId,
                newPass: "",
                confirmNewPass: "",
                buttonClicked: false,
                loading: false,
            };
        }

        return null;
    }

    handleChangePassDialogSave(event) {
        this.setState({ buttonClicked: true, loading: true });

        if (this.state.newPass !== this.state.confirmNewPass) {
            this.setState({ alertText: i18n.t('text.passwordsNotMatch'), loading: false });
            return;
        }
        server.users.changePass(this.state.userId, this.state.newPass).then(result => {
            if (!hasError(result)) {
                this.setState({ loading: false });
                this.onClose();
            } else {
                this.setState({ alertText: formatError(result), loading: false });
            }
        }).catch(error => {
            this.setState({ alertText: formatError(error), loading: false });
        });
        
    }

    handlePasswordValueChanged(event) {
        this.setState({ newPass: event.target.value });
    }

    handleRepeatPasswordValueChanged(event) {
        this.setState({ confirmNewPass: event.target.value });
    }


    render() {
        return (
            <Dialog open={this.state.dialogOpen}>
                <DialogTitle>{i18n.t('text.changePassword')}</DialogTitle>
                <DialogContent>

                    {
                        this.state.alertText && (
                            <Alert severity="error">
                                <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                                {this.state.alertText}
                            </Alert>
                        )
                    }

                    <TextField
                        autoFocus
                        margin="dense"
                        id="changePassField"
                        error={this.state.buttonClicked && (this.state.newPass === "" || this.state.confirmNewPass === "") }
                        label={i18n.t('text.newPassword')}
                        type="password"
                        defaultValue=""
                        fullWidth
                        onChange={(event) => this.handlePasswordValueChanged(event)}
                    />
                    <TextField
                        margin="dense"
                        id="changePassField2"
                        error={this.state.buttonClicked && (this.state.newPass === "" || this.state.confirmNewPass === "") }
                        label={i18n.t('text.confirmNewPassword')}
                        type="password"
                        defaultValue=""
                        fullWidth
                        onChange={(event) => this.handleRepeatPasswordValueChanged(event)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.loading} onClick={(event) => this.onClose()}>{i18n.t("buttons.cancel")}</Button>
                    <LoadingButton loading={this.state.loading} onClick={(event) => this.handleChangePassDialogSave(event)}>{i18n.t("buttons.change")}</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

}
