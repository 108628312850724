import React from 'react';
import { Outlet } from "react-router-dom";


function DocsLayout(props) {
    return (
        <div>
            <Outlet />
        </div>
    );
}

export default DocsLayout;
