import RequestAbstract from "../abstract";

export default class ReferenceInfo extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/reference-info";
    }

}
