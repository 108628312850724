import React, { useState, useEffect } from 'react';
import { fDate } from "../../utils/formatTime";
import { CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import server from "../../server";
import { useNavigate } from "react-router-dom";

const AllTestResultsSelect = ({ testId, currentRole }) => {
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchTests = async () => {
            try {
                setLoading(true);
                const res = await server.tests.getAll();
                const filteredTests = res.data.filter(test => test.status === 'result_received');
                setTests(filteredTests);
                setSelectedTest(testId);
            } catch (error) {
                console.error("Error fetching tests:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTests();

    }, [testId]);

    const handleTestChange = (event) => {
        setSelectedTest(event.target.value);
        navigate("/app/user-test-results/" + event.target.value);
    };

    return (
        <Select
            sx={{ minWidth: '350px' }}
            value={selectedTest}
            displayEmpty
            onChange={(event) => handleTestChange(event)}
        >
            { loading &&
                <MenuItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} value="">
                    <CircularProgress size={20} thickness={5} />
                </MenuItem> }
            {tests.length ? tests.map((test, index) => {
                const userName = test.user.firstName + " " + test.user.lastName;
                return (
                    <MenuItem key={index} value={test.id}>
                        <Typography fontSize={18} sx={{ color: 'text.primary' }}>
                            <b>{fDate(test.dateCreated)}</b> {currentRole !== "user" ? " - " + userName : ""} [{test.serial}]
                        </Typography>
                    </MenuItem>
                );
            }) : null}

        </Select>
    );
};

export default AllTestResultsSelect;
