import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader, } from "@zxing/library";
import Webcam from "react-webcam";
import { Box, Container, } from "@mui/material";

const LabBarcodeScanner = ({
    onUpdate,
    onError,
    width = "100%",
    height = "100%",
    facingMode = "environment",
    delay = 200,
    stopStream,
}) => {

    const [trackWidth, setTrackWidth] = useState(0);
    const [trackHeight, setTrackHeight] = useState(0);

    const webcamRef = useRef(null);

    const capture = useCallback(() => {
        const codeReader = new BrowserMultiFormatReader();
        const imageSrc = webcamRef?.current?.getScreenshot();
        if (imageSrc) {

            if (trackHeight === 0 || trackWidth === 0) {
                let i = new Image();
                i.onload = function () {
                    setTrackWidth(i.width);
                    setTrackHeight(i.height);
                };
                i.src = imageSrc;
                // setTrackHeight(1);
                // setTrackWidth(1);
            }

            codeReader
                .decodeFromImage(undefined, imageSrc)
                .then((result) => {
                    onUpdate(null, result);
                })
                .catch((err) => {
                    onUpdate(err);
                });
        }
    }, [trackHeight, trackWidth, onUpdate]);

    useEffect(() => {
        const stream = webcamRef?.current?.video?.srcObject;
        if (stream) {
            const track = stream?.getVideoTracks()[0];
            track.applyConstraints({
                width: { min: 320, ideal: 640 },
                height: { min: 240, ideal: 480 },
                advanced: [{ torch: true }],
            })
                .catch((err) => onUpdate(err));
        }
    }, [onUpdate]);

    useEffect(() => {
        if (stopStream) {
            let stream = webcamRef?.current?.video.srcObject;
            if (stream) {
                stream.getTracks().forEach((track) => {
                    stream.removeTrack(track);
                    track.stop();
                });
                stream = null;
            }
        }
    }, [stopStream]);

    useEffect(() => {
        const interval = setInterval(capture, delay);
        return () => {
            clearInterval(interval);
        };
    }, [capture, delay]);

    const containerWidth = width;
    const boxWidth = 200; const boxHeight = 120;
    const k = trackWidth / containerWidth;

    return (
        <Container width={width} sx={{ position: 'relative' }}>
            {trackWidth > 1 && trackHeight > 1 && (
                <Box sx={{
                    width: boxWidth,
                    height: boxHeight,
                    marginTop: (trackHeight / k - boxHeight) / 2 + "px",
                    marginLeft: (trackWidth / k - boxWidth) / 2 + "px",
                    border: '3px solid #00800080',
                    position: 'absolute'
                }} />
            )}
            <Webcam
                width={width}
                height={height}
                ref={webcamRef}
                forceScreenshotSourceSize={true}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    facingMode,
                    width: 640,
                    height: 480
                }}
                audio={false}
                onUserMediaError={onError}
            />
        </Container>

    );
};

export default LabBarcodeScanner;
