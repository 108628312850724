import RequestAbstract from "../abstract";

export default class Notifications extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/notifications";
    }

}
