import React from 'react';

// @mui
import {
    TextField,
} from '@mui/material';

export default class DialogTestDataField extends React.Component {

    render() {
        // field, value, cache, onChange

        const { id } = this.props.field;

        return (
            <>
                {
                    Array(22).fill(1).map((x, i) =>
                        (<TextField
                            autoFocus
                            margin="dense"
                            id={id + "_" + i}
                            label={i + 1}
                            type="number"
                            sx={{ width: 100 }}
                            //onChange={(event) => this.handleDialogTestDataValueChanged(event)} 
                        />)
                    )
                }
            </>
        );
    }
}
