import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
    Card,
    CardHeader,
    Box,
    ListItemText,
    List,
    ListItem,
    IconButton,
    Typography,
    TablePagination
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDialog from 'pages/dialogs/ConfirmDialog';

import i18n from 'i18';
import Preloader from '../../components/Preloader';
import helper from '../../utils/helper';

AppList.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    data: PropTypes.array
};

export default function AppList({
    title,
    subheader,
    data,
    confirmDeleteText,
    onItemDelete,
    ...other
}) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpenDialog = (id) => {
        setSelectedItemId(id);
        setConfirmDialogOpen(true);
    };

    useEffect(() => {
        if (confirmDelete) {
            onItemDelete(selectedItemId).then(() => setSelectedItemId(null));
            setConfirmDialogOpen(false);
            setConfirmDelete(false);
        }
    }, [confirmDelete]);

    const rowsPerPage = 5;

    const pageData = data
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [];

    return (
        <>
            <ConfirmDialog
                dialogOpen={confirmDialogOpen}
                onConfirm={() => setConfirmDelete(true)}
                onCancel={() => setConfirmDialogOpen(false)}
                alertText={confirmDeleteText}
                alertTitle={i18n.t('dialogs.confirmationTitle')}
            />

            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minHeight: 400
                }} {...other}
            >
                <CardHeader title={title} subheader={subheader} />

                {data && (
                    <Box sx={{ p: 3, pb: 1, minHeight: 300 }} dir="ltr">
                        <List>
                            {pageData.map((item) => {
                                return (
                                    <ListItem
                                        key={item.id}
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() =>
                                                    handleOpenDialog(item.id)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText>
                                            {item.title}
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                        {data && data.length === 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    pb: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                dir="ltr"
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: '#637381',
                                        paddingTop: '120px'
                                    }}
                                >
                                    {i18n.t('dashboard.noDataAvailable')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {!data && <Preloader />}

                <TablePagination
                    component="div"
                    labelDisplayedRows={helper.labelDisplayedRows}
                    rowsPerPageOptions={[]}
                    count={data ? data.length : 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={5}
                    sx={{ display: 'flex', alignSelf: 'flex-end' }}
                />
            </Card>
        </>
    );
}
