import React from 'react';

import i18n from 'i18';

// @mui
import { TableCell, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Label from '../../components/label';

// Text, Number, Items cells are similar
export default class GridLabelCell extends React.Component {
    determineLabelColor = (value) => {
        switch (value) {
        case 'result_received':
            return 'success';
        default:
            return 'error';
        }
    };
    renderTooltipLabel = (value) => (
        <Tooltip title={i18n.t('text.cancelledTip')}>
            <Label color="error" endIcon={<InfoIcon />}>
                {i18n.t(`enums.${value}`)}
            </Label>
        </Tooltip>
    );

    renderLabel = (value) => (
        <Label color={this.determineLabelColor(value)}>
            {i18n.t(`enums.${value}`)}
        </Label>
    );

    render() {
        const { value, field } = this.props;
        return (
            <TableCell key={field.id} align="left">
                {value === 'cancelled'
                    ? this.renderTooltipLabel(value)
                    : this.renderLabel(value)}
            </TableCell>
        );
    }
}
