import React from 'react';

import i18n from 'i18';

// @mui
import { TableCell } from '@mui/material';

// Text, Number, Items cells are similar
export default class GridItemsCell extends React.Component {
    render() {
        // props: value, id, field
        return (
            <TableCell align="left">
                {i18n.t(`enums.${this.props.value}`)}
            </TableCell>
        );
    }
}
