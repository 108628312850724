import React from "react";
// @mui
import { TableCell } from "@mui/material";
import i18 from "../../i18";

// Text, Number, Items editorCells are similar
const styles = {
    controllerCell: {
        backgroundColor: "#F4F6F8",
        fontWeight: "bold",
    },
};
export default class GridTextCell extends React.Component {
    render() {
        // props: value, id, field
        const display = this.props.field.display;
        const value = display ? this.props.value[display] : this.props.value;
        return (
            <TableCell
                key={this.props.field.id}
                align="left"
                sx={this.props.field.needStyle && styles.controllerCell}
            >
                {this.props.field.needTranslate
                    ? i18.t(`enums.${value}`)
                    : value}
            </TableCell>
        );
    }
}
