import React from 'react';

import i18n from 'i18';

// @mui
import {
    FormControlLabel,
    Checkbox,
} from '@mui/material';

export default class DialogStatusField extends React.Component {


    handleCheckboxChanged(event, value) {
        this.props.onChange(event.target.id, value);
    }

    render() {
        // field, value, cache, onChange

        let translatedName = i18n.t(`columns.${this.props.field.label}`);

        return (
            <FormControlLabel 
                control={
                    <Checkbox 
                        id={this.props.field.id} 
                        defaultChecked={this.props.value} 
                        onChange={(event, checked) => this.handleCheckboxChanged(event, checked)} 
                    />} 
                label={translatedName} 
            />
        );
    }
}
