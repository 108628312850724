// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class CategoriesPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "referenceInfoCategories";
        this.Server = server.referenceInfoCategories;
        this.searchFields = ['name', 'parentCategory.name' ];


        this.Head = [
            { id: 'name', label: 'name', alignRight: false, cell: 'Text' },
            { id: 'language', label: 'language', alignRight: false, cell: 'Items', items: ['en', 'de'] },
            { id: 'defaultFolded', label: 'defaultFolded', alignRight: false, cell: 'Boolean' },
            { id: 'parentCategoryId', label: 'parentCategory', alignRight: false, display: 'name',
                object: 'parentCategory', cell: 'Select', from: 'referenceInfoCategories' },
            { id: 'position', label: 'position', alignRight: false, cell: 'Number' },
            { id: 'description', label: 'description', alignRight: false, cell: 'RichText' },
        ];


    }

}
