import React from 'react';
import OutlinedInput from "@mui/material/OutlinedInput";
import { MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import i18 from "../../../i18";

const MultipleSelect = ({ selectedItems, items, handleChange, label }) => {
    return (
        <Select
            multiple
            value={selectedItems}
            onChange={(event) => handleChange(event.target.value)}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) =>
                selected.map((el) => i18.t(`enums.${el}`)).join(", ")
            }
        >
            {items.length
                ? items.map((item) => (
                    <MenuItem key={item} value={item} id={item}>
                        <Checkbox
                            checked={selectedItems.indexOf(item) > -1}
                        />
                        <ListItemText primary={i18.t(`enums.${item}`)} />
                    </MenuItem>
                ))
                : null}
        </Select>
    );
};

export default MultipleSelect;