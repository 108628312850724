import RequestAbstract from "../abstract";

export default class TherapistUsers extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/therapist-users";
    }

}
