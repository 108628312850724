import { IconButton } from '@mui/material';
import { useSlate } from 'slate-react';
import { isMarkActive, toggleMark } from './helpers';

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();


    return (
        <IconButton
            color={isMarkActive(editor, format) ? 'primary' : 'black'}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {icon}
        </IconButton>
    );
};

export default MarkButton;
