import RequestAbstract from "../abstract";

export default class UserInviteCodes extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/user-invite-codes";
    }

    async getInviteCode() {
        return this.get("api/user-invite-codes/get-invite-code");
    }

    async applyInviteCode(code) {
        return this.post("api/user-invite-codes/apply-invite-code", { code });
    }

}
