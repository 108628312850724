import React from 'react';

// @mui
import {
    TableCell,
} from '@mui/material';
import { fDate } from 'utils/formatTime';

export default class GridDateCell extends React.Component {

    render() {
        // props: value, id, field
        return (<TableCell align="left">{fDate(this.props.value)}</TableCell>);
    }

}
