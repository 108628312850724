import { IconButton } from "@mui/material";
import { useSlate } from "slate-react";
import { isBlockActive, toggleBlock, TEXT_ALIGN_TYPES } from "./helpers";

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <IconButton
            color={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            ) ? "primary" : "black"}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            {icon}
        </IconButton>
    );
};

export default BlockButton;
