import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, Box, Link, Typography } from '@mui/material';

// ----------------------------------------------------------------------

AppBox.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    children: PropTypes.element
};

export default function AppBox({
    title,
    subheader,
    subtitleWithLink,
    link,
    children,
    styles,
    ...other
}) {
    let beforeLink = '';
    let afterLink = '';
    if (subtitleWithLink) {
        [beforeLink, afterLink] = subtitleWithLink.split(link);
    }

    return (
        <Card sx={{ height: '100%', ...styles }} {...other}>
            {title || subheader ? (
                <CardHeader
                    title={title}
                    subheader={subheader}
                    sx={{ flexBasis: 'auto', flexGrow: 1 }}
                />
            ) : null}

            {subtitleWithLink ? (
                <Typography variant="subtitle1" sx={{ p: 3, pb: 0 }}>
                    {beforeLink}
                    <Link href={'https://' + link} target="_blank" underline="hover">
                        {link}
                    </Link>
                    {afterLink}
                </Typography>
            ) : null}

            <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                {children}
            </Box>
        </Card>
    );
}
