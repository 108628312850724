import React from 'react';

import i18n from 'i18';

// @mui
import {
    TextField,
} from '@mui/material';

export default class DialogTextField extends React.Component {

    render() {
        // field, value, cache, onChange

        let translatedName = i18n.t(`columns.${this.props.field.label}`);

        return (<TextField 
            autoFocus
            margin="dense" 
            id={this.props.field.id} 
            label={translatedName} 
            type="text" 
            defaultValue={this.props.value || ""} 
            fullWidth 
            onChange={(event) => this.props.onChange(event.target.id, event.target.value)} 
        />
        );
    }
}
