import React from 'react';

// @mui
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField, Stack, Alert
} from '@mui/material';

import i18n from 'i18';
import { LoadingButton } from '@mui/lab';
import RichText from '../../components/richtext';
import server from '../../server';


export default class AddAnnouncementDialog extends React.Component {

    onClose = () => { };

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            title: "",
            description: "",
            richText: "",
            error: "",
            loading: false,

        };

        this.onClose = props.onClose;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen,
            };
        }

        return null;
    }

    handleDialogSave = async (event, name) => {
        let data = {
            title: this.state.title,
            description: this.state.description,
            richText: this.state.richText,
        };

        if (!data.title || !data.description || !data.richText) {
            this.setState({ error: i18n.t("errors.fillAllRequiredFields") });
            return;
        }

        this.setState({ loading: true });
        
        let result = await server.notifications.add(data);

        if (result.error !== 'OK') {
            this.setState({ error: i18n.t(`errors.${result.error}`), loading: false });
        } else {
            this.setState({ loading: false });
            this.onClose();
        }
    };

    handleDialogClose = (event, name) => {
        this.onClose();
    };

    // Title
    // Description
    // Wysiwyg

    render() {

        return (
            <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
                <DialogTitle>
                    {i18n.t("dialogs.addAnnouncement")}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label={i18n.t("dialogs.announcementTitle")}
                        variant="outlined"
                        margin="normal"
                        name="title"
                        value={this.state.title}
                        onChange={(event) => this.setState({ title: event.target.value })}
                    />
                    <TextField
                        fullWidth
                        label={i18n.t("dialogs.announcementDescription")}
                        variant="outlined"
                        margin="normal"
                        name="description"
                        value={this.state.description}
                        onChange={(event) => this.setState({ description: event.target.value })}
                    />

                    <RichText
                        label={i18n.t("dialogs.announcementWysiwyg")}
                        value={this.state.richText}
                        onChange={(value) => this.setState({ richText: value })}
                    />
                    {this.state.error && (
                        <Stack sx={{ marginTop: 2 }}>
                            <Alert severity="error">{this.state.error}</Alert>
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.loading} onClick={(event) => this.handleDialogClose(event)}>{i18n.t("buttons.close")}</Button>
                    <LoadingButton loading={this.state.loading} onClick={(event) => this.handleDialogSave(event)}>{i18n.t("buttons.save")}</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

}
