import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography, Alert, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'components/iconify';
// server
import server from 'server';
import i18n from 'i18';
import LanguagePopover from 'popovers/LanguagePopover';
import { formatError, hasError } from 'utils/formatError';
import helper from 'utils/helper';

// ----------------------------------------------------------------------

export default function ForgotForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");

    const [buttonText, setButtonText] = useState(i18n.t("auth.forgotContinue"));

    const [step, setStep] = useState(1); // 1,2,3
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        server.auth.forgot({
            email: username,
            code,
            password,
        }).then(result => {
            if (!hasError(result)) {
                let currentStep = step + 1;
                setLoading(false);
                setStep(currentStep);

                if (currentStep === 3) {
                    setLoading(false);
                    setButtonText(i18n.t("auth.forgotSetNewPassword"));
                } else if (currentStep > 3) {
                    setLoading(false);
                    navigate('/app', { replace: true });
                }
            } else {
                setLoading(false);
                setError(formatError(result));
            }
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <>
            <Helmet>
                <title>{helper.formPageTitle("auth.forgot")}</title>
            </Helmet>

            <Stack direction="row" justifyContent="space-between">

                <Typography variant="h4" gutterBottom>
                    {i18n.t("auth.forgotTitle")}
                </Typography>

                <LanguagePopover forceSmall={true} />

            </Stack>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>

                <TextField
                    name="username"
                    label={i18n.t("columns.email")}
                    value={username}
                    disabled={step > 1}
                    onChange={event => setUsername(event.target.value)}
                />

                {step > 1 && (
                    <TextField
                        name="code"
                        label={i18n.t("columns.code")}
                        value={code}
                        disabled={step > 2}
                        onChange={event => setCode(event.target.value)}
                    />
                )}

                {step === 3 && (
                    <TextField
                        name="password"
                        label={i18n.t("columns.newPassword")}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

            </Stack>

            <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} onClick={handleClick}>
                {buttonText}
            </LoadingButton>

            {error && <Alert severity="error">{error}</Alert>}

            <Divider sx={{ my: 2 }} />

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
                <Link variant="subtitle2" underline="hover" onClick={() => navigate("/auth/login")}>
                    {i18n.t("auth.loginLink")}
                </Link>
            </Stack>

        </>
    );
}
