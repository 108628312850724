import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Container, Typography } from '@mui/material';
import { useWidth } from 'hooks/useResponsive';

const Line = ({ width, color }) => {
    return <Box sx={{ width: width, height: 10, backgroundColor: color }} />;
};

const DelimiterDotted = () => {
    return (<Box sx={{
        borderStyle: 'dotted',
        borderWidth: 1,
        borderRadius: 1,
        width: 2,
        height: 20,
    }} />);
};

const DelimiterSolid = ({ value }) => {
    return (<Box sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 1,
        marginLeft: `${value}px`,
        width: 2,
        height: 20,
        position: 'absolute',
    }} />);
};

const MainValue = ({ value, text }) => {
    return (<Typography noWrap sx={{
        borderWidth: 1,
        borderRadius: 1,
        marginLeft: `${value}px`,
    }}>
        {text}
    </Typography>);
};

const RangeValue = ({ value, text }) => {
    return (<Typography noWrap sx={{
        position: 'absolute',
        borderWidth: 1,
        borderRadius: 1,
        fontSize: 12,
        marginLeft: `${value}px`,
    }}>
        {text}
    </Typography>);
};

export default function TestValueWithRange({ value, rangeMin, rangeMax, dimension }) {

    let theme = useTheme();
    let screen = useWidth();

    let widthPixels;
    if (screen === "md") {
        widthPixels = 300;
    } else if (screen === "sm") {
        widthPixels = 280;
    } else if (screen === "xs") {
        widthPixels = 200;
    } else {
        widthPixels = 320;
    }

    let rangeInside = rangeMax - rangeMin;

    let minSlider = rangeMin - rangeInside;
    if (minSlider > value) minSlider = value - rangeInside;
    if (minSlider < 0) minSlider = 0;

    let maxSlider = rangeMax + rangeInside;
    if (maxSlider < value) maxSlider = value + rangeInside;

    let sliderInside = maxSlider - minSlider;
    let kx = widthPixels / sliderInside;

    // console.log([minSlider, rangeMin, value, rangeMax, maxSlider, kx]);

    return (
        <Container sx={{ height: 60, paddingTop: value === -1 ? 2 : 0 }}>
            { value !== -1 && <MainValue value={(value - minSlider) * kx - 20} text={value + " " + dimension} /> }
            { value !== -1 && <DelimiterSolid value={(value - minSlider) * kx} /> }
            <Stack sx={{ width: widthPixels, height: 20 }} alignItems="center" direction="row">
                <Line width={(rangeMin - minSlider) * kx} color={theme.palette.error.light} />
                <DelimiterDotted />
                <Line width={(rangeMax - rangeMin) * kx} color={theme.palette.success.light} />
                <DelimiterDotted />
                <Line width={(maxSlider - rangeMax) * kx} color={theme.palette.error.light} />
            </Stack>
            <RangeValue value={(rangeMin - minSlider) * kx - 5} text={rangeMin} />
            <RangeValue value={(rangeMax - minSlider) * kx - 5} text={rangeMax} />
        </Container>
    );
}
