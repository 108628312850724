import React from 'react';

// @mui
import { TableCell } from '@mui/material';
import { formatValue } from '../../utils/formatName';

export default class GridSelectCell extends React.Component {
    render() {
        // props: value, id, field
        const display = this.props.field.display;
        const value = formatValue(this.props.value, display);

        return <TableCell align="left">{value}</TableCell>;
    }
}
