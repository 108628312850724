import Auth from "./auth";

import Tests from "./crud/tests";
import Users from "./crud/users";
import ResellerTests from "./crud/resellerTests";
import Therapists from "./crud/therapists";
import TherapistUsers from "./crud/therapistUsers";
import UserInviteCodes from "./crud/userInviteCodes";
import ReferenceInfo from "./crud/referenceInfo";
import ReferenceInfoCategories from "./crud/referenceInfoCategories";
import RolePermissions from "./crud/rolePermissions";
import Notifications from "./crud/notifications";


const server = {
    auth: new Auth(),
    tests: new Tests(),
    users: new Users(),
    resellerTests: new ResellerTests(),
    therapists: new Therapists(),
    therapistUsers: new TherapistUsers(),
    userInviteCodes: new UserInviteCodes(),
    referenceInfo: new ReferenceInfo(),
    referenceInfoCategories: new ReferenceInfoCategories(),
    rolePermissions: new RolePermissions(),
    notifications: new Notifications(),
};

export default server;
