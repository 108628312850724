import config from "config";

export default class RequestAbstract {

    _config = config;

    async postForm(link, data, headers = {}) {
        const body = Object.entries(data).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

        const { language } = this._config;

        return await this.load(link, {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/x-www-form-urlencoded',
                'Language': localStorage.language || language
            },
            body
        });
    }

    async getAll() {
        return await this.getAllFiltered();
    }

    async search(limit, filter = [], sort = [], props) {
        return await this.getFiltered(this.link, filter, sort, {
            page: 0,
            pageSize: limit || 1000
        }, props);
    }

    async getAllFiltered(filter = [], sort = [{ field: "id", direction: "DESC" }], props) {
        return await this.getFiltered(this.link, filter, sort, {
            page: 0,
            pageSize: 1000
        }, props);
    }

    async getFiltered(link, filter, sort, paging, props = {}) {
        return await this.post(link, { filter, sort, paging, ...props });
    }

    async add(info) {
        return await this.loadWithAuth(this.link + "/add/", {
            method: 'POST',
            body: JSON.stringify(info)
        });
    }

    async update(id, info) {
        return await this.loadWithAuth(this.link + "/update/" + id, {
            method: 'POST',
            body: JSON.stringify(info)
        });
    }

    async delete(ids) {
        return await this.loadWithAuth(this.link + "/delete/" + ids.join(","), {
            method: 'GET'
        });
    }

    async getUnfiltered() {
        return await this.get(this.link);
    }

    async get(link) {
        return await this.loadWithAuth(link, { method: 'GET' });
    }

    async post(link, props) {
        return await this.loadWithAuth(link, {
            method: 'POST',
            body: JSON.stringify(props)
        });
    }

    async loadWithAuth(link, props = {}, binaryData) {
        if (!props.headers)
            props.headers = {};

        const { language } = this._config;

        props.headers["X-Auth-Token"] = localStorage.token;
        props.headers["Language"] = localStorage.language || language;
        props.headers["Accept-Language"] = localStorage.language || language;
        props.headers["Content-Type"] = 'application/json';

        return await this.load(link, props, binaryData);
    }

    async load(link, props, binaryData = false) {
        let resp = {};
        const url = this.getFullLink(link);

        let status = 0;
        try {
            const response = await fetch(url, props);

            status = response.status;

            if (binaryData) {
                resp = await response.blob();
            } else {
                resp = await response.json();
            }
        } catch (error) {
            resp = { code: "error", status, error: error.statusText };
        }
        if (status === 401) {
            //if (!globalThis.appView.state.loggedIn)
            //    helper.showRequestError(resp, helper.tr("server.loginPassError"));
            //else
            //    helper.logout();

            //return {};
        }

        //if (resp?.code !== "success" && !link.includes(this.links.token))
        //helper.showRequestError(resp);

        return resp;
    }

    getFullLink(link) {
        return "/" + link;
    }

    getSortField(sortKey, direction) {
        const sort = [];
        if (sortKey) {
            sort.push({
                field: sortKey,
                direction
            });
        }
        return sort;
    }

}
