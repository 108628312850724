import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    Checkbox,
    Alert,
    Divider,
    RadioGroup,
    Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'components/iconify';
// server
import server from 'server';
// language
import i18n from 'i18';
import LanguagePopover from 'popovers/LanguagePopover';
// utils
import { formatError, hasError } from 'utils/formatError';
import helper from 'utils/helper';

// ----------------------------------------------------------------------

function RoleNameText(props) {
    return (
        <Typography sx={{ fontWeight: 'bold' }} {...props}>
            {props.children}
        </Typography>
    );
}

function RoleDescriptionText(props) {
    return (
        <Typography sx={{ fontSize: 12, opacity: 0.8, px: 6 }} {...props}>
            {props.children}
        </Typography>
    );
}

export default function RegistrationForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);

    const [role, setRole] = useState('user');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [birthDate, setBirthDate] = useState(null);
    // const [gender, setGender] = useState('');
    const [company, setCompany] = useState('');

    const [error, setError] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setError('');
        setButtonClicked(true);

        let data = {
            role,
            email,
            firstName,
            lastName,
            password,
            // gender,
            // birthDate: moment(birthDate).format('YYYY-MM-DD')
        };

        if (role === 'therapist') {
            data = {
                ...data,
                company
            };
            if (!company) {
                setError(i18n.t('errors.fillAllRequiredFields'));
                return;
            }
        }

        if (
            !email ||
            !firstName ||
            !lastName ||
            !password
        ) {
            setError(i18n.t('errors.fillAllRequiredFields'));
            return;
        }

        if (email !== confirmEmail) {
            setError(i18n.t('error.emailAndConfirmationDoNotMatch'));
            return;
        }

        if (password !== confirmPassword) {
            setError(i18n.t('errors.passwordAndConfirmationDoNotMatch'));
            return;
        }

        if (!accepted) {
            setError(i18n.t('errors.acceptPrivacyAndTerms'));
            return;
        }

        setLoading(true);

        server.auth.register(data).then((result) => {
            if (!hasError(result)) {
                setLoading(false);
                navigate('/app', { replace: true });
            } else {
                setLoading(false);
                setError(formatError(result));
            }
        });
    };

    // const locale = i18n.language === 'en' ? 'en-gb' : i18n.language;

    return (
        <>
            <Helmet>
                <title>{helper.formPageTitle('auth.register')}</title>
            </Helmet>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    {i18n.t('auth.registerTitle')}
                </Typography>

                <LanguagePopover forceSmall={true} />
            </Stack>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>
                <RadioGroup
                    color="primary"
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                    aria-label="Role"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio value="user"></Radio>
                        <RoleNameText>{i18n.t('enums.user')}</RoleNameText>
                    </Stack>
                    <RoleDescriptionText>
                        {i18n.t('auth.whoIsUser')}
                    </RoleDescriptionText>

                    <Divider sx={{ my: 3 }} />

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio value="therapist">
                            {i18n.t('enums.therapist')}
                        </Radio>
                        <RoleNameText>{i18n.t('enums.therapist')}</RoleNameText>
                    </Stack>
                    <RoleDescriptionText>
                        {i18n.t('auth.whoIsTherapist')}
                    </RoleDescriptionText>
                </RadioGroup>

                <Divider sx={{ my: 3 }} />

                {role === 'therapist' && (
                    <TextField
                        name="company"
                        label={i18n.t('columns.company')}
                        value={company}
                        required
                        error={buttonClicked && company === ''}
                        onChange={(event) => setCompany(event.target.value)}
                    />
                )}

                <TextField
                    name="firstName"
                    label={i18n.t('columns.firstName')}
                    value={firstName}
                    required
                    error={buttonClicked && firstName === ''}
                    onChange={(event) => setFirstName(event.target.value)}
                />

                <TextField
                    name="lastName"
                    label={i18n.t('columns.lastName')}
                    value={lastName}
                    required
                    error={buttonClicked && lastName === ''}
                    onChange={(event) => setLastName(event.target.value)}
                />

                {/*<LocalizationProvider*/}
                {/*    dateAdapter={AdapterMoment}*/}
                {/*    adapterLocale={locale}*/}
                {/*>*/}
                {/*    <DatePicker*/}
                {/*        sx={{ width: '100%', my: 1 }}*/}
                {/*        name="birthDate"*/}
                {/*        label={i18n.t(`columns.birthDate`)}*/}
                {/*        value={birthDate}*/}
                {/*        onError={(reason, value) => {*/}
                {/*            console.log('reason:', reason, 'value:', value);*/}
                {/*            if (reason === 'invalidDate') {*/}
                {/*                return i18n.t('errors.invalidDate');*/}
                {/*            }*/}
                {/*            return '';*/}
                {/*        }}*/}
                {/*        onChange={(value) => setBirthDate(value)}*/}
                {/*    />*/}
                {/*</LocalizationProvider>*/}

                {/*<TextField*/}
                {/*    name="gender"*/}
                {/*    label={i18n.t('columns.gender')}*/}
                {/*    select*/}
                {/*    value={gender}*/}
                {/*    onChange={(event) => setGender(event.target.value)}*/}
                {/*>*/}
                {/*    {['male', 'female'].map((item) => (*/}
                {/*        <MenuItem key={item} value={item}>*/}
                {/*            {i18n.t(`enums.${item}`)}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</TextField>*/}

                <TextField
                    name="email"
                    label={i18n.t('columns.email')}
                    value={email}
                    required
                    error={buttonClicked && email === ''}
                    onChange={(event) => setEmail(event.target.value)}
                />

                <TextField
                    name="confirmEmail"
                    label={i18n.t('columns.confirmEmail')}
                    value={confirmEmail}
                    required
                    error={
                        buttonClicked &&
                        (confirmEmail === '' || email !== confirmEmail)
                    }
                    onChange={(event) => setConfirmEmail(event.target.value)}
                />

                <TextField
                    name="password"
                    label={i18n.t('columns.password')}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    required
                    error={
                        buttonClicked &&
                        (password === '' || password !== confirmPassword)
                    }
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    <Iconify
                                        icon={
                                            showPassword
                                                ? 'eva:eye-fill'
                                                : 'eva:eye-off-fill'
                                        }
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <TextField
                    name="confirmPassword"
                    label={i18n.t('columns.confirmPassword')}
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    required
                    error={
                        buttonClicked &&
                        (confirmPassword === '' || password !== confirmPassword)
                    }
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    <Iconify
                                        icon={
                                            showPassword
                                                ? 'eva:eye-fill'
                                                : 'eva:eye-off-fill'
                                        }
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <Stack direction="row" alignItems="center">
                    <Checkbox
                        checked={accepted}
                        onChange={(e) => setAccepted(e.target.checked)}
                    />
                    <Typography>
                        {i18n.t('auth.iaccept')}{' '}
                        <Link target="_blank" href="/docs/privacy">
                            {i18n.t('auth.privacyPolicy')}
                        </Link>{' '}
                        {i18n.t('auth.and')}{' '}
                        <Link target="_blank" href="/docs/tos">
                            {i18n.t('auth.termsAndConditions')}
                        </Link>
                    </Typography>
                </Stack>
            </Stack>

            <LoadingButton
                loading={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
                onClick={handleClick}
            >
                {i18n.t('auth.register')}
            </LoadingButton>

            {error && <Alert severity="error">{error}</Alert>}

            <Divider sx={{ my: 2 }} />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2, cursor: 'pointer' }}
            >
                <Link
                    variant="subtitle2"
                    underline="hover"
                    onClick={() => navigate('/auth/login')}
                >
                    {i18n.t('auth.loginLink')}
                </Link>
            </Stack>
        </>
    );
}
