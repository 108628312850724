import { createContext } from "react";

export class AppStore {
    #state;
    #subscriptions = new Set();

    constructor(initialState) {
        this.#state = {
            ...initialState
        };
    }

    getState() {
        return this.#state;
    }

    update(partialState) {
        const { ...rest } = partialState;
        this.#state = { ...this.#state, ...rest };

        this.#subscriptions.forEach((cb) => cb());
    }

    subscribe(cb) {
        this.#subscriptions.add(cb);
        return () => this.#subscriptions.delete(cb);
    }
}


export const AppDataContext = createContext();

