import i18n from "i18";

export function formatError(result) {
    if (result.data) {
        return result.data.join("\r\n");
    }

    if (result.message) {
        return i18n.t("backend." + result.message);
    }

    return result.error;
}

export function hasError(result) {
    return result.error !== "OK";
}
