import i18n from 'i18';

export const formatValue = (item, display) => {
    if (!item) {
        return '';
    }
    if (!display) {
        return i18n.t(`enums.${item}`);
    }
    if (display.includes(',')) {
        // if display has user need to show first and last name
        const parts = display.split(', ');
        const name = item[parts[0]]
            ? `${item[parts[0]].firstName} ${item[parts[0]].lastName}`
            : item[parts[0]].name
                ? item[parts[0]].name
                : '';
        const info = item[parts[1]] ? `(${item[parts[1]]})` : '';
        return `${name} ${info}`;
    }
    let parts = display.split('+');
    return parts.map((part) => item[part]).join(' ');
};

export const formatController = (name) => {
    // from 'testName' to 'test-name'
    return name.replace(/([A-Z])/g, '-$1').toLowerCase();
};
