// translations
import i18n from 'i18';
import moment from "moment";

const helper = {
    async changeLanguage(lang) {
        localStorage.language = lang;
        lang === 'en' ? moment.locale('en-gb') : moment.locale(lang);
        await i18n.changeLanguage(lang);
        globalThis.forceUpdate();
    },

    formPageTitle(key) {
        return `${i18n.t(key)} | Kingnature`;
    },
    labelDisplayedRows({ from, to, count }) {
        return `${from}-${to} ${i18n.t('text.of')} ${
            count !== -1
                ? count
                : `${i18n.t('text.moreThan')} ${to}`
        }`;
    },
    adaptedName(name) {
        return name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    },
};

export default helper;