import React from 'react';

import i18n from 'i18';

// @mui
import {
    Button, 
    Box,
} from '@mui/material';

export default class DialogUploadField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filename: ""
        };
    }

    handleFileUpload(event) {
        const id = this.props.field.id;
        const file = event.target.files[0];
        const { name } = file;
        this.setState({ filename: name });

        const reader = new FileReader();
        reader.onload = (readerEvent) => {
            if (!readerEvent?.target?.result) {
                return;
            }

            const { result } = readerEvent.target;
            console.log(result);

            this.props.onChange(id, result);

        };
        reader.readAsDataURL(file);
        
    }

    render() {
        // field, value, cache, onChange

        let translatedName = i18n.t(`columns.upload_${this.props.field.label}`);
        let fileType = this.props.fileType || "jrxml";

        return (
            <>
                <Button
                    component="label"
                    variant="outlined"
                    sx={{ marginRight: "1rem", height: "55px" }}
                >
                    {translatedName} (.{fileType})
                    <input type="file" accept={fileType} hidden onChange={(event) => this.handleFileUpload(event)} />
                </Button>
                <Box>{this.state.filename}</Box>
            </>
        );
    }
}
