import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import Logo from 'components/logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(12, 0),
        minHeight: '100vh',
    },
}));

// ----------------------------------------------------------------------

export default function AuthLayout(props) {

    console.log(props);

    delete localStorage.user;
    delete localStorage.role;
    delete localStorage.token;
    delete localStorage.notifications;

    return (
        <>
            <StyledRoot>

                <Container maxWidth="sm">

                    <Logo
                        sx={{
                            position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'fixed' },
                            top: { xs: 0, sm: 0, md: 0, lg: 40 },
                            left: { xs: 0, sm: 0, md: 0, lg: 40 },
                            width: 200,
                        }}
                    />

                    <StyledContent>
                        <Outlet />
                    </StyledContent>

                </Container>
            </StyledRoot>
        </>
    );
}
