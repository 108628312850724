import { Navigate, Routes, Route, useParams, useNavigate, } from 'react-router-dom';
// layouts
import MainLayout from './layouts/main';
import SimpleLayout from './layouts/simple';
import AuthLayout from './layouts/auth';
// pages
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
// grid pages
import UsersPage from './pages/grids/UsersPage';
import TestsPage from './pages/grids/TestsPage';
import TherapistUsersPage from './pages/grids/TherapistUsersPage';
import ResellerTestsPage from './pages/grids/ResellerTestsPage';
import TherapistsPage from './pages/grids/TherapistsPage';
import UserInviteCodesPage from './pages/grids/UserInviteCodesPage';
// auth pages
import LoginPage from './pages/auth/LoginPage';
import LoginGoogleFinishPage from './pages/auth/LoginGoogleFinishPage';
import RegistrationPage from './pages/auth/RegistrationPage';
import ForgotPage from './pages/auth/ForgotPage';
import ReferenceInfoPage from './pages/grids/ReferenceInfoPage';
import UserTestResults from './pages/results/UserTestResults';
import PrivacyPolicy from './pages/docs/PrivacyPolicy';
import TermsOfService from './pages/docs/TermsOfService';
import DocsLayout from './layouts/DocsLayout';
import CategoriesPage from "./pages/grids/CategoriesPage";
import PermissionEditorPage from "./pages/grids/PermissionEditorPage";
import AnnouncementsPage from './pages/grids/AnnouncementsPage';

// ----------------------------------------------------------------------

function withRouteProps(Component, optionalProps = {}) {
    return (props) => {
        return (
            <Component
                {...props}
                {...optionalProps}
                params={useParams()}
                navigate={useNavigate()}
            />
        );
    };
}

export default function Router(props) {
    return (
        <Routes>
            <Route path="/app" element={<MainLayout {...props} />}>
                <Route path="/app" index={true} element={<Navigate to="/app/dashboard" />} />
                <Route path={"/app/dashboard"} Component={withRouteProps(DashboardAppPage, props)} />
                <Route path={"/app/users"} element={<UsersPage {...props} />} />
                <Route path={"/app/therapists"} element={<TherapistsPage {...props} />} />
                <Route path={"/app/user-invite-codes"} element={<UserInviteCodesPage {...props} />} />
                <Route path={"/app/tests"} element={<TestsPage {...props} />} />
                <Route path={"/app/therapist-users"} element={<TherapistUsersPage {...props} />} />
                <Route path={"/app/reseller-tests"} element={<ResellerTestsPage {...props} />} />
                <Route path={"/app/reference-info"} element={<ReferenceInfoPage {...props} />} />
                <Route path={"/app/user-test-results/:id"} Component={withRouteProps(UserTestResults, props)} />
                <Route path={"/app/categories"} element={<CategoriesPage {...props} />} />
                <Route path={"/app/announcements"} element={<AnnouncementsPage {...props} />} />
                <Route path={"/app/role-permissions"} element={<PermissionEditorPage {...props} /> } />
            </Route>
            <Route path="/docs" element={<DocsLayout />}>
                <Route path="/docs/privacy" element={<PrivacyPolicy />} />
                <Route path="/docs/tos" element={<TermsOfService />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
                <Route path="/auth" index={true} element={<Navigate to="/auth/login" />} />
                <Route path="/auth/login" element={<LoginPage />} />
                <Route path="/auth/login-by-google" element={<LoginGoogleFinishPage />} />
                <Route path="/auth/register" element={<RegistrationPage />} />
                <Route path="/auth/forgot" element={<ForgotPage />} />
            </Route>
            <Route path="/" element={<SimpleLayout />}>
                <Route path="/" index={true} element={<Navigate to="/app/dashboard" />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Route>
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    );
}
