import {
    Alert,
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import i18n from '../../i18';
import React from 'react';
import server from '../../server';
import PropTypes from 'prop-types';

export default class AddAnotherUserTestDialog extends React.Component {
    onSave = () => {};
    onClose = () => {};
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: props.dialogOpen || false,
            items: props.items.filter((it) => it.role === 'user') || [],
            userId: '',
            serial: '',
            gender: '',
            loading: false,
            alertText: ''
        };
        this.onSave = props.onSave;
        this.onClose = props.onClose;
        console.log('items', this.state.items);
        console.log('props items', props.items);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen,
                items: props.items.filter((it) => it.role === 'user') || [],
                userId: '',
                serial: '',
                loading: false,
                alertText: ''
            };
        }

        return null;
    }

    handleSerialChange = (value) => {
        if (value.match(/^[0-9]*$/) && value.length <= 8) {
            this.setState({ serial: value });
        }
    };

    handleDialogSave = async () => {
        const { userId, serial } = this.state;
        let data = {
            userId,
            serial,
        };
        this.setState({ loading: true });
        server.tests.addAnotherUserTest(data).then((result) => {
            if (result.error === 'OK') {
                this.onSave();
            } else {
                this.setState({
                    loading: false,
                    alertText: i18n.t(`backend.${result.message}`)
                });
            }
        });
    };

    sortItems = (items) => {
        return items.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    };

    render() {
        const { items, userId } = this.state;
        const sortedItems = this.sortItems(items);
        console.log('items', items);
        console.log('sortedItems', sortedItems);
        return (
            <Dialog open={this.state.dialogOpen}>
                <DialogTitle>
                    {i18n.t('dialogs.addAnotherUserTest')}
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={sortedItems}
                        getOptionLabel={(option) => option.value}
                        value={
                            sortedItems.find((item) => item.id === userId) ||
                            null
                        }
                        onChange={(event, newValue) => {
                            console.log('newValue', newValue);
                            this.setState({
                                userId: newValue ? newValue.id : null,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                key={userId}
                                {...params}
                                margin="dense"
                                label={i18n.t('columns.user')}
                            />
                        )}
                        renderOption={(props, option) => (
                            <MenuItem {...props} key={option.id} value={option.id}>
                                {option.value}
                            </MenuItem>
                        )}
                    />

                    <TextField
                        margin="dense"
                        id="serial"
                        name="serial"
                        label={i18n.t('columns.serial')}
                        fullWidth
                        value={this.state.serial}
                        onChange={(event) =>
                            this.handleSerialChange(event.target.value)
                        }
                    />

                    {this.state.alertText && (
                        <Alert
                            severity="error"
                            onClose={() => this.setState({ alertText: '' })}
                        >
                            {this.state.alertText}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onClose()}>
                        {i18n.t('buttons.cancel')}
                    </Button>

                    <LoadingButton
                        loading={this.state.loading}
                        onClick={(e) => this.handleDialogSave(e)}
                        variant="contained"
                        color="primary"
                    >
                        {i18n.t('buttons.save')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}
AddAnotherUserTestDialog.propTypes = {
    dialogOpen: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    items: PropTypes.array,
};
