import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
// components
import Logo from 'components/logo';
import Scrollbar from 'components/scrollbar';
import NavSection from 'components/nav-section';
//
import LanguagePopover from 'popovers/LanguagePopover';
import AccountPopover from 'popovers/AccountPopover';
import i18n from 'i18';
import config from 'config';
import { cloneDeep } from 'lodash';
import { useStateSelector } from '../../../context/StateSelectors';
import helper from '../../../utils/helper';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const permissions = useStateSelector(state => state.permissions);
    const { pathname } = useLocation();

    const hasReadPermission = (permissionKey) => {
        const formattedKey = helper.adaptedName(permissionKey);
        return permissions[formattedKey] && permissions[formattedKey].read !== "deny";
    };

    const filterMenuItems = (menu) => {
        const navMenu = cloneDeep(menu);
        if (!permissions || !Object.keys(permissions).length) return [];
        return navMenu.filter((menuItem) => {
            return hasReadPermission(menuItem.controller);
        });
    };
    const navConfig = filterMenuItems(config.menu);

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                "height": 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                <Logo sx={{
                    width: 200
                }} />
            </Box>

            <NavSection data={navConfig.map(it => {
                const title = i18n.t(it.titleKey);

                return { ...it, title };
            })} />

            <Box sx={{ flexGrow: 1 }} />

            {isDesktop && (
                <>
                    <LanguagePopover />
                    <AccountPopover />
                </>
            )}

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
