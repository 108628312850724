import React from "react";
//mui
import { Box, Button, IconButton, MenuItem, TableCell } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
//local
import i18 from "../../i18";

export default function EditorSelectCell({
    field,
    row,
    handleSave,
    handleChange,
    currentValues,
}) {
    const [selectVisible, setSelectVisible] = React.useState(false);
    const [originalValue, setOriginalValue] = React.useState(null);
    const { id } = row;
    const permissionsVariables = ["allow_all", "allow_own", "deny"];
    const value = currentValues[id][field.id];
    const handleClick = (event) => {
        setOriginalValue(value);
        setSelectVisible(!selectVisible);
    };
    const handleCancel = async () => {
        await handleChange({ target: { value: originalValue } }, row, field.id);
        if (value !== originalValue) handleSave(row.id, row.role, row.controller);
        setSelectVisible(false);
    };
    const handleSend = async () => {
        await handleSave(row.id, row.role, row.controller);
        setSelectVisible(false);
    };

    return (
        <TableCell key={field.id} align="left">
            {selectVisible ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel>{i18.t(`text.choose_permissions`)}</InputLabel>
                        <Select
                            labelId={field.label}
                            id={field.id}
                            value={value || ""}
                            label="Choose permission"
                            onChange={(event) => handleChange(event, row.id, field.id)}
                        >
                            {permissionsVariables.map((permission) => {
                                return (
                                    <MenuItem
                                        key={permission + "_" + field.id}
                                        value={permission}
                                    >
                                        {i18.t(`permissions.${permission}`)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "stretch",
                        }}
                    >
                        <IconButton onClick={handleSend}>
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            ) : (
                <Button color="inherit" onClick={(event) => handleClick(event)}>
                    {i18.t(`permissions.${value}`)}
                </Button>
            )}
        </TableCell>
    );
}
