import DialogTextField from "./DialogTextField";
import DialogItemsField from "./DialogItemsField";
import DialogNumberField from "./DialogNumberField";
import DialogSelectField from "./DialogSelectField";
import DialogStatusField from "./DialogStatusField";
import DialogTestDataField from "./DialogTestDataField";
import DialogUploadField from "./DialogUploadField";
import DialogRichTextField from "./DialogRichTextField";
import DialogDateField from "./DialogDateField";

const Fields = {
    Text: DialogTextField,
    Items: DialogItemsField,
    Number: DialogNumberField,
    Select: DialogSelectField,
    Status: DialogStatusField,
    TestData: DialogTestDataField,
    Upload: DialogUploadField,
    RichText: DialogRichTextField,
    Boolean: DialogStatusField,
    Date: DialogDateField,
};

export default Fields;
