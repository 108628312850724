import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AppDataContext } from "./Context";
export const useStore = () => {
    const store = useContext(AppDataContext);

    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }

    return store;
};

export const useStateSelector = (selector) => {
    const store = useStore();
    const [state, setState] = useState(() => selector(store.getState()));
    const selectorRef = useRef(selector);
    const stateRef = useRef(state);

    useLayoutEffect(() => {
        selectorRef.current = selector;
        stateRef.current = state;
    });

    useEffect(() => {
        return store.subscribe(() => {
            const value = selectorRef.current(store.getState());

            if (stateRef.current === value) {
                return;
            }

            setState(value);
        });
    }, [store]);

    return state;

};