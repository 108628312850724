import {
    Announcement,
    Biotech,
    Category,
    Dashboard,
    Group,
    MedicalInformation,
    Storefront,
    SupervisedUserCircle,
    VerifiedUser
} from '@mui/icons-material';

const config = {
    language: 'de',
    languages: ['de', 'en'],
    langs: [
        {
            value: 'en',
            label: 'English',
            iconKey: 'GB'
        },
        {
            value: 'de',
            label: 'German',
            iconKey: 'DE'
        }
    ],
    defaultSort: {
        field: 'id',
        direction: 'desc'
    },
    menu: [
        {
            titleKey: 'menu.dashboard',
            path: '/app/dashboard',
            icon: <Dashboard />,
            controller: 'dashboard'
        },
        {
            titleKey: 'pages.tests.title',
            path: '/app/tests',
            icon: <Biotech />,
            controller: 'tests'
        },
        {
            titleKey: 'pages.users.title',
            path: '/app/users',
            icon: <Group />,
            controller: 'users'
        },
        {
            titleKey: 'pages.therapists.title',
            path: '/app/therapists',
            icon: <Storefront />,
            controller: 'therapists'
        },
        // {
        //     titleKey: 'pages.resellerTests.title',
        //     path: '/app/reseller-tests',
        //     icon: <Badge />,
        //     controller: 'resellerTests'
        // },
        {
            titleKey: 'pages.therapistUsers.title',
            path: '/app/therapist-users',
            icon: <SupervisedUserCircle />,
            controller: 'therapistUsers'
        },
        {
            titleKey: 'pages.userInviteCodes.title',
            path: '/app/user-invite-codes',
            icon: <VerifiedUser />,
            controller: 'userInviteCodes'
        },
        {
            titleKey: 'pages.referenceInfo.title',
            path: '/app/reference-info',
            icon: <MedicalInformation />,
            controller: 'referenceInfo'
        },
        {
            titleKey: 'pages.referenceInfoCategories.title',
            path: '/app/categories',
            icon: <Category />,
            controller: 'referenceInfoCategories'
        },
        {
            titleKey: 'pages.notifications.title',
            path: '/app/announcements',
            icon: <Announcement />,
            controller: 'notifications'
        }
    ]
};

export default config;
