import RequestAbstract from "../abstract";

export default class RolePermissions extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/role-permissions";
    }

}
