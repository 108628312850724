import React from 'react';
import OutlinedInput from "@mui/material/OutlinedInput";
import { MenuItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import i18 from "../../../i18";

const SingleSelect = ({ selectedItem, items, handleChange, label }) => {
    const changeValue = (event) => {
        handleChange([event.target.value]);
    };
    return (
        <Select
            value={selectedItem}
            onChange={(event) => changeValue(event)}
            input={<OutlinedInput label={label} />}
        >
            {items.length
                ? items.map((item) => (
                    <MenuItem key={item} value={item} id={item}>
                        <ListItemText primary={i18.t(`enums.${item}`)} />
                    </MenuItem>
                ))
                : null}
        </Select>
    );
};

export default SingleSelect;