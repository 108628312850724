import RequestAbstract from "../abstract";

export default class Therapists extends RequestAbstract {

    constructor() {
        super();

        this.link = "api/therapists";
    }

}
