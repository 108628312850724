import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Link, Stack, TextField, Typography, Checkbox, Alert, Divider, RadioGroup, Radio } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// server
import server from 'server';
import i18n from 'i18';
import LanguagePopover from 'popovers/LanguagePopover';
import { formatError, hasError } from 'utils/formatError';
import helper from 'utils/helper';

// ----------------------------------------------------------------------

function RoleNameText(props) {
    return (
        <Typography
            sx={{ fontWeight: "bold" }}
            {...props}>
            {props.children}
        </Typography>
    );
}

function RoleDescriptionText(props) {
    return (
        <Typography
            sx={{ fontSize: 12, opacity: 0.8, px: 6 }}
            {...props}>
            {props.children}
        </Typography>
    );
}

export default function LoginGoogleFinishForm() {

    const navigate = useNavigate();
    const location = useLocation();
    const { email, firstName, lastName, clientId, credential } = location.state;

    console.log(location);


    const [role, setRole] = useState("user");

    const [error, setError] = useState("");
    const [accepted, setAccepted] = useState(false);

    const handleClick = async () => {

        setError("");

        if (!accepted) {
            setError(i18n.t("errors.acceptPrivacyAndTerms"));
            return;
        }

        server.auth.loginWithGoogle(clientId, credential, role).then((result) => {
            if (!hasError(result)) {
                console.log(result);
                console.log(localStorage);
                navigate('/app', { replace: true });
            } else {
                setError(formatError(result));
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>{helper.formPageTitle("auth.register")}</title>
            </Helmet>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    {i18n.t("auth.registerTitle")}
                </Typography>

                <LanguagePopover forceSmall={true} />
            </Stack>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>

                <RadioGroup
                    color="primary"
                    value={role}
                    onChange={event => setRole(event.target.value)}
                    aria-label="Role"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio value="user"></Radio>
                        <RoleNameText>{i18n.t("enums.user")}</RoleNameText>
                    </Stack>
                    <RoleDescriptionText>{i18n.t("auth.whoIsUser")}</RoleDescriptionText>

                    <Divider sx={{ my: 3 }} />

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio value="therapist">{i18n.t("enums.therapist")}</Radio>
                        <RoleNameText>{i18n.t("enums.therapist")}</RoleNameText>
                    </Stack>
                    <RoleDescriptionText>{i18n.t("auth.whoIsTherapist")}</RoleDescriptionText>
                </RadioGroup>

                <Divider sx={{ my: 3 }} />

                <TextField
                    name="email"
                    label={i18n.t("columns.email")}
                    value={email}
                    required
                    disabled={true}
                />

                <TextField
                    name="firstName"
                    label={i18n.t("columns.firstName")}
                    value={firstName}
                    required
                    disabled={true}
                />

                <TextField
                    name="lastName"
                    label={i18n.t("columns.lastName")}
                    value={lastName}
                    required
                    disabled={true}
                />

                <Stack direction="row" alignItems="center">
                    <Checkbox value={accepted} onChange={(e) => setAccepted(e.target.value)} />
                    <Typography>{i18n.t("auth.iaccept")} <Link target="_blank" href="/docs/privacy">{i18n.t("auth.privacyPolicy")}</Link> {i18n.t("auth.and")} <Link target="_blank" href="/docs/tos">{i18n.t("auth.termsAndConditions")}</Link></Typography>
                </Stack>

            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} onClick={handleClick}>
                {i18n.t("auth.register")}
            </LoadingButton>

            {error && <Alert severity="error">{error}</Alert>}

            <Divider sx={{ my: 2 }} />

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
                <Link variant="subtitle2" underline="hover" onClick={() => navigate("/auth/login")}>
                    {i18n.t("auth.loginLink")}
                </Link>
            </Stack>

        </>
    );
}
