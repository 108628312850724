import React from 'react';

// @mui
import {
    TableCell,
} from '@mui/material';

// Text, Number, Items cells are similar
export default class GridTestDataCell extends React.Component {

    render() {
        // props: value, id, field
        return (<TableCell align="left">{"Valid"}</TableCell>);
    }

}
