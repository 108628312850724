import React from 'react';
import { Container, Typography } from "@mui/material";

function TermsOfService() {
    return (
        <Container>
            <Typography align={"justify"}>
                <Typography variant="h4" gutterBottom align={"center"} mt={5}>
                    Privacy Policy
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Einwilligungserklärung
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Identität und Kontaktdaten des Verantwortlichen
                </Typography>
                <Typography variant="body1" gutterBottom>
                    kingnature AG, Staubstrasse 1, 8038 Zürich, info@kingnature.ch.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. Kategorien der bearbeiteten Daten
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Name, Wohnsitz, Geburtsdatum, Geschlecht, Gesundheitsdaten (insbesondere Blutwerte), Kontaktdaten
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Ziel und Zweck der Datenbearbeitung
                </Typography>
                <div>
                    <Typography variant="body1" gutterBottom>
                        3.1. Auswertung der Daten für den Kunden gemäss Vereinbarung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Kunde erhält eine Laboranalyse der Biomarker und soll dadurch in die Lage versetzt werden, allfällige Mängel zu erkennen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.2. Auswertung der Daten für Forschung und Statistik
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Mithilfe einer anonymisierten Auswertung soll gezeigt werden können, dass Mikronährstoffmängel durch die gezielte Einnahme von Nahrungsergänzungsmitteln behoben werden können und dass Prävention durch gezieltes Supplementieren eine positive Wirkung auf die Gesundheit haben kann.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.3. Übrige Zwecke der Datenbearbeitung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Weiter werden die Daten genützt, soweit dies zur Abrechnung gegenüber Kunden und Dritten oder zur Geltendmachung oder Verteidigung von Rechtsansprüchen der kingnature AG erforderlich ist. Aufgrund gesetzlicher Vorschriften kann die Weitergabe von Daten an Behörden erforderlich sein, wie etwa Meldungen nach dem Krebsregistrierungsgesetz.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    4. Art und Weise der Datenbearbeitung und Massnahmen zur Datensicherheit
                </Typography>
                <div>
                    <Typography variant="body1" gutterBottom>
                        4.1. Auswertung der Daten für den Kunden
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Blutentnahme via TAP-Device wird als Selbsttest selbständig durchgeführt. Die Blutproben werden von Kunden im beiliegenden dafür vorgesehenen Versandbeutel per Post an das Labor gesendet. Die Auswertung / Laboranalyse wird durch die SwissAnalysis GmbH durchgeführt. Diese ist ein etabliertes Labor für die klinische Diagnostik und als Speziallabor landesweit bekannt.
                        Je nach gewähltem Profil werden die Spiegel von bis zu ca. 40 Biomarkern im Blut gemessen (z.B. Vitamin D, Zink, Selen, usw.). Allfällige Mikronährstoffmängel werden dabei aufgezeigt und gespeichert. Die so gewonnenen Daten werden allenfalls mit den dem Kunden empfohlenen und von ihm erworbenen Produkten verknüpft, die die aufgezeigten Mängel womöglich beseitigen können.
                        Der Kunde kann alle seine Biomarker-Spiegel auf Basis des gewählten Profils sehen. Ausser der kingnature AG und dem Labor hat niemand Einsicht in diese Daten. Dem Kunden steht es jedoch frei, dem Reseller oder Therapeuten Einblick in seine Daten zu gewähren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.2. Datenübermittlung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Datentransfer zwischen dem Labor und unserer Datenbank erfolgt gemäß dem HL7-Standard (Health Level Seven) und gewährleistet eine nahtlose und strukturierte Kommunikation. HL7 erleichtert den sicheren Austausch von gesundheitsbezogenen Informationen, einschließlich Bluttestergebnissen, und sorgt gleichzeitig für die Integrität der Daten und die Kompatibilität zwischen den Systemen.

                        Sämtliche Daten werden in einem hochsicheren Rechenzentrum in der Schweiz gehostet, das höchsten Datenschutz- und Sicherheitsstandards entspricht.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        4.3. Wann und durch wen werden die Blutproben vernichtet?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Das schweizerische Labor behandelt (vernichtet) die Blutproben gemäss den anwendbaren abfallrechtlichen Bestimmungen in der Schweiz.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        4.4. Auswertung der Daten für Forschung und Statistik
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Das Labor analysiert die Blutproben und ermittelt und speichert die Biomarker auch für den Zweck Forschung und Statistik.
                        In anonymisierter Form können Erkenntnisse aus der Auswertung der Daten Versicherungen und Partnern im Gesundheitssystem, welche im Bereich der Gesundheitsprävention tätig sind, bekanntgegeben werden, um damit bessere Entscheidungen im Bereich der Gesundheitsprävention treffen zu können. Die Anonymisierung der Daten erfolgt vor ihrer Bekanntgabe.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    5. Empfänger oder Kategorien von Empfängern, denen Personendaten bekanntgegeben werden
                </Typography>

                <div>
                    <Typography variant="body1" gutterBottom>
                        Zu den obengenannten Zwecken werden Kundendaten an ein Labor bekanntgegeben.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Erkenntnisse aus der Auswertung der Daten können Versicherungen und Partnern im Gesundheitssystem bekanntgegeben werden, wie oben beschrieben.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Kundendaten können einer Rechtsanwaltskanzlei oder einem Inkassobüro bekanntgegeben werden, soweit dies zur Durchsetzung oder Verteidigung von Ansprüchen der kingnature AG erforderlich ist.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Eine Bekanntgabe von Daten an Reseller oder Therapeuten erfolgt nur nach ausdrücklicher Zustimmung des Kunden.
                    </Typography>
                </div>

                <Typography variant="h6" gutterBottom>
                    6. Risiken der Bearbeitung von Gesundheitsdaten
                </Typography>

                <div>
                    <Typography variant="body1" gutterBottom>
                        Bei den bearbeiteten Blutdaten handelt es sich um Gesundheitsdaten. Gesundheitsdaten sind besonders schützenswert, weil eine Verletzung der Datensicherheit die Persönlichkeit und die Grundrechte der betroffenen Personen besonders stark beeinträchtigen kann. Trotz sorgfältiger Datenschutzmassnahmen verbleiben stets Risiken für die Datensicherheit.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        So kann niemals zur Gänze ausgeschlossen werden, dass unbefugte Personen von den Gesundheitsdaten Kenntnis erlangen, was mehrere Risiken zur Folge haben könnte:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        1. Diskriminierung, z.B. durch Bekanntwerden ansteckender oder stigmatisierender Erkrankungen;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2. Finanzieller Verlust, z.B. durch Verlust des Beschäftigungsverhältnisses durch Bekanntwerden einer chronischen Erkrankung, welche z.B. häufige längerfristige krankheitsbedingte Ausfalle im Berufsleben beinhaltet;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3. Rufschädigung, z.B. durch Bekanntwerden des Vorliegens einer sexuell übertragbaren Erkrankung.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ausserdem besteht stets ein Restrisiko der falschen Zuordnung von Daten und Kunden (Verwechslung). Diesfalls könnten die resultierenden Analysen unrichtig und daraufhin getroffene Massnahmen (z.B. Supplementierungen) für die Gesundheit unwirksam oder nachteilig sein.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Zuordnung der Blutproben und der Laboranalyse erfolgt durch das Labor. Dieser Prozess wird von den Kontrollbehörden überprüft.
                    </Typography>

                </div>


                <Typography variant="h6" gutterBottom>
                    7. Aufbewahrungsdauer oder Kriterien zur Festlegung dieser Dauer.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Die Daten werden so lange aufbewahrt, wie es zur Erfüllung der oben genannten Zwecke erforderlich ist. Anschliessend steht es der kingnature AG frei, die Daten für bis zu 15 Jahre zu archivieren oder sie zu löschen.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. Kategorien von Daten, die nicht bei der betroffenen Person beschafft werden
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Keine.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    9. Bekanntgabe von Daten ins Ausland
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Keine.
                </Typography>
            </Typography>

        </Container>
    );
}

export default TermsOfService;
