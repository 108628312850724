import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MultipleSelect from "./MultipleSelect";
import SingleSelect from "./SingleSelect";
import i18 from "../../../i18";

class FilterItemsField extends React.Component {
    field;
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedItems: [],
        };
        this.field = props.field;
    }

    componentDidMount() {
        this.setState({
            items: this.field.items,
        });
    }

    componentDidUpdate(
        prevProps,
        prevState,
        snapshot
    ) {
        if (
            prevProps.clearAllFilters !== this.props.clearAllFilters &&
      this.props.clearAllFilters
        ) {
            this.removeFilters();
        }
    }

    handleChange(value) {
        this.setState({
            selectedItems: value,
        });
        this.props.handleFilter(this.field.id, value);
    }

    removeFilters() {
        this.setState({
            selectedItems: [],
        });
        this.props.handleFilter(this.field.id, []);
    }

    render() {
        const translatedLabel = i18.t(`text.filter_by_${this.field.label}`);
        return (
            <FormControl sx={{ width: "350px", m: 1 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                    {translatedLabel}
                </InputLabel>
                {this.field.multiple ? (
                    <MultipleSelect
                        label={translatedLabel}
                        items={this.state.items}
                        selectedItems={this.state.selectedItems}
                        handleChange={(value) => this.handleChange(value)}
                    />
                ) : (
                    <SingleSelect
                        label={translatedLabel}
                        items={this.state.items}
                        selectedItem={
                            this.state.selectedItems.length
                                ? this.state.selectedItems.at(0)
                                : ""
                        }
                        handleChange={(value) => this.handleChange(value)}
                    />
                )}
            </FormControl>
        );
    }
}

export default FilterItemsField;
