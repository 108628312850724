import React from 'react';

import i18n from 'i18';

// @mui
import {
    TableCell,
} from '@mui/material';

import Label from 'components/label';

export default class GridStatusCell extends React.Component {

    render() {
        // props: value, id, field
        return (
            <TableCell align="left">
                <Label color={this.props.value ? 'success' : 'error'}>{this.props.value ? i18n.t("values.active") : i18n.t("values.disabled")}</Label>
            </TableCell>
        );
    }

}
