import { useEffect } from 'react';

export const LoginWithApple = () => {

    useEffect(() => {

        window.onAppleSuccess = (response) => {
            // hit your backend, passing up response.credential
        };

        // Inject the google provided script 
        // (an importable module would be nicer here)
        const script = document.createElement('script');
        script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
        script.async = true;
        document.body.appendChild(script);

        document.body.addEventListener("load", () => {
            window.AppleID.auth.init({
                clientId: '[CLIENT_ID]',
                scope: '[SCOPES]',
                redirectURI: '[REDIRECT_URI]',
                state: '[STATE]',
                nonce: '[NONCE]',
                usePopup: true
            });
        }, false);
    

        return () => {
            // clean up for react lifecycle
            window.onAppleSuccess = undefined;
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div id="appleid-signin" className="signin-button" data-color="black" data-border="true" data-width="100%" data-height="40" data-type="sign-in"></div>
        </>
    );
};
