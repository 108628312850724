import React from 'react';

// @mui
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    AlertTitle
} from '@mui/material';

import { hasError, formatError } from 'utils/formatError';

import server from 'server';
import i18n from 'i18';
import Fields from '../editors';
import { LoadingButton } from '@mui/lab';

export default class UpdateProfileDialog extends React.Component {
    onClose = () => {};
    Head;

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            alertText: props.alertText,
            alertTitle: props.alertTitle,
            loading: false,
        };

        this.onClose = props.onClose;
        this.Head = props.Head;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen || false,
                alertText: props.alertText,
                alertTitle: props.alertTitle,
                dialogData: props.dialogData
            };
        }

        return null;
    }

    handleUpdateProfileDialogSave(event) {
        this.setState({ loading: true });
        server.users.updateProfile(this.state.dialogData).then((result) => {
            if (!hasError(result)) {
                this.setState({ loading: false });
                this.onClose();
            } else {
                this.setState({ loading: false });
                this.setState({ alertText: formatError(result) });
            }
        });
    }

    handleProfileValueChanged(id, value) {
        let dialogData = this.state.dialogData;
        dialogData[id] = value;
        this.setState({ dialogData: dialogData });
    }

    render() {
        let data = this.state.dialogData || {};
        return (
            <Dialog open={this.state.dialogOpen}>
                <DialogTitle>{i18n.t('text.updateProfile')}</DialogTitle>
                <DialogContent>
                    {this.state.alertText && (
                        <Alert severity="error">
                            <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                            {this.state.alertText}
                        </Alert>
                    )}

                    {this.Head.map((field) => {
                        let Field = Fields[field.cell];
                        if (!Field) Field = Fields.Text; // fallback to text
                        return (
                            <Field
                                key={field.id}
                                field={field}
                                value={data[field.id]}
                                cache={this.cache}
                                onChange={(id, value) =>
                                    this.handleProfileValueChanged(id, value)
                                }
                            />
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.loading} onClick={(event) => this.onClose()}>
                        {i18n.t('buttons.cancel')}
                    </Button>
                    <LoadingButton
                        loading={this.state.loading}
                        onClick={(event) =>
                            this.handleUpdateProfileDialogSave(event)
                        }
                    >
                        {i18n.t('buttons.save')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}
