import React from 'react';

import i18n from 'i18';

// @mui
import {
    TableCell,
} from '@mui/material';

// Text, Number, Items cells are similar
export default class GridRichTextCell extends React.Component {

    render() {
        // props: value, id, field

        const length = this.props.value ? this.props.value.length : 0;

        return (<TableCell align="left">{length > 0 ? length + " " + i18n.t("text.bytes") : ""}</TableCell>);
    }

}
